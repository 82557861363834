import { useRef } from 'react';

/**
 * Does a one time initialization. On all subsequent renders, it will return the saved value.
 */
export function useConstant<T>(fn: () => T): T {
  const ref = useRef<{ v: T }>();

  if (!ref.current) {
    ref.current = { v: fn() };
  }

  return ref.current.v;
}
