import { navigate } from '@reach/router';
import _isFunction from 'lodash/isFunction';

import { EventParticipantResponse } from '@stur/models/event-participant-model';

import { DOMUtils } from './dom-utils';

/**
 * Defines functions for generating all possible routes in the app
 */
const routes = {
  logIn: (returnPath?: string, keepParams: string[] = ['return-url']) =>
    getRoute('/', { 'return-url': returnPath }, keepParams),
  createAccount: (returnPath?: string, keepParams: string[] = ['return-url']) =>
    getRoute('/create-account', { 'return-url': returnPath }, keepParams),
  dashboard: () => getRoute(process.env.APP_PATH),
  eventDetail: (
    eventId: string,
    response?: EventParticipantResponse,
    pollId?: string,
    votes?: string[]
  ) =>
    getRoute(`${process.env.APP_PATH}/event/${eventId}`, {
      rsvp: response,
      poll: pollId,
      votes: votes?.join(','),
    }),
  forgotPassword: (keepParams: string[] = ['return-url']) =>
    getRoute('/forgot-password', undefined, keepParams),
  linkAccount: (keepParams: string[] = ['return-url']) =>
    getRoute('/link-account', undefined, keepParams),
  completeAccount: (keepParams: string[] = ['return-url']) =>
    getRoute(`${process.env.APP_PATH}/complete-account`, undefined, keepParams),
  notFound: () => getRoute('/404'),
  privacyPolicy: () => getRoute('/privacy-policy'),
  terms: () => getRoute('/terms'),
} as const;

/**
 * Get an absolute URL to a given route by prepending the site URL
 */
function getAbsoluteUrl(route: string): string {
  return new URL(route, process.env.SITE_URL).href;
}

function getRouteOrReturnPath(route: string): string {
  if (!DOMUtils.isSSR()) {
    const currentParams = new URLSearchParams(window.location.search);
    const returnPath = currentParams.get('return-url');

    if (returnPath) {
      return returnPath;
    }
  }

  return route;
}

/**
 * Get page route with optional querystring params
 * Allows keeping a predefined set of existing querystring params, if present
 */
function getRoute(
  pagePath: string,
  params?: Record<string, string | undefined>,
  keepParams?: string[]
): string {
  const newParams = new URLSearchParams();

  if (!DOMUtils.isSSR() && keepParams) {
    const currentParams = new URLSearchParams(window.location.search);

    keepParams?.forEach((key) => {
      const value = currentParams.get(key);
      if (value) {
        newParams.set(key, value);
      }
    });
  }

  if (params) {
    Object.keys(params).forEach((key) => {
      const value = params[key];
      if (value) {
        newParams.set(key, value);
      }
    });
  }

  const queryString = newParams.toString();
  return pagePath + (queryString ? `${pagePath === '/' ? '' : '/'}?${queryString}` : '');
}

function getQueryParams(): Record<string, string> {
  const queryParams: Record<string, string> = {};

  if (!DOMUtils.isSSR()) {
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.forEach((value, key) => (queryParams[key] = value));
  }

  return queryParams;
}

async function clearQueryParams(): Promise<void> {
  if (!DOMUtils.isSSR() && window.location.search) {
    if (_isFunction(window.history?.replaceState)) {
      // use History API directly if available to avoid scroll to top
      window.history.replaceState(window.history.state, '', window.location.pathname);
    } else {
      navigate(window.location.pathname);
    }
  }
}

export const RoutingUtils = {
  routes,
  clearQueryParams,
  getQueryParams,
  getAbsoluteUrl,
  getRouteOrReturnPath,
} as const;
