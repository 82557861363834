type EditableElement = HTMLInputElement | HTMLTextAreaElement;

/**
 * Check whether the page is being rendered on the server during build time
 * as opposed to on the client at runtime
 *
 * @returns true if rendering on the server
 */
function isSSR(): boolean {
  return typeof window === 'undefined';
}

/**
 * Check whether the current page is only rendered at runtime and not at
 * build time.
 *
 * Any page url beginning with process.env.APP_PATH is a client only page.
 *
 * @returns true if page only renders on client side
 */
function isClientOnlyPage(): boolean {
  if (isSSR()) {
    return false;
  }

  return !!window.location?.pathname.startsWith(process.env.APP_PATH);
}

/**
 * Select text in an element in a way that works on desktop and iOS
 *
 * @returns success
 */
function selectText(
  editableEl: EditableElement,
  selectionStart: number,
  selectionEnd: number
): boolean {
  editableEl.focus();

  const range = document.createRange();
  range.selectNodeContents(editableEl);

  const selection = window.getSelection();
  if (selection) {
    selection.removeAllRanges();
    selection.addRange(range);
    editableEl.setSelectionRange(selectionStart, selectionEnd);
    return true;
  }

  return false;
}

/**
 * Copies a text to clipboard using older text selection method in case
 * navigator.clipboard.writeText is unsupported
 *
 * @returns success
 */
function copyTextFallback(text: string, editableEl?: EditableElement): boolean {
  let textArea = editableEl;

  if (!textArea) {
    textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed';
    textArea.style.left = '-9999px';
    textArea.style.top = '0px';
    textArea.style.opacity = '0';
    textArea.readOnly = false;
    textArea.contentEditable = 'true';
    document.body.append(textArea);
  }

  if (!selectText(textArea, 0, text.length)) {
    return false;
  }

  let success = false;
  try {
    success = document.execCommand('copy', false);
  } catch {
    success = false;
  } finally {
    if (!editableEl) {
      textArea.remove();
    }
  }

  return success;
}

/**
 * Copies text to clipboard in a cross-browser compatible way
 *
 * @returns success
 */
async function copyTextToClipboard(text: string, editableEl?: EditableElement): Promise<boolean> {
  try {
    await window.navigator.clipboard.writeText(text);
    return true;
  } catch {
    return copyTextFallback(text, editableEl);
  }
}

export const DOMUtils = {
  copyTextToClipboard,
  isClientOnlyPage,
  isSSR,
} as const;
