export const breakpoints = ['small', 'medium', 'large', 'xlarge', 'xxlarge'] as const;

export type Breakpoint = typeof breakpoints[number];

export const breakpointValues: Record<Breakpoint, number> = {
  small: 0,
  medium: 640,
  large: 1024,
  xlarge: 1200,
  xxlarge: 1400,
} as const;
