import { FriendModel } from '@stur/models/friend-model';
import { FriendUserModel } from '@stur/models/friend-user-model';
import { UserModel } from '@stur/models/user-model';

import { CacheService } from './cache-service';
import { FirebaseService } from './firebase-service';

async function getUser(uid: string): Promise<UserModel | null> {
  return FirebaseService.withSession(async (session) => {
    const userDoc = await CacheService.getDocument<UserModel>(session, `/users/${uid}`);
    return userDoc || null;
  });
}

async function getFriendUser(uid: string, authUserId?: string): Promise<FriendUserModel | null> {
  return FirebaseService.withSession(async (session) => {
    const userDocPromise = CacheService.getDocument<UserModel>(session, `/users/${uid}`);

    if (authUserId && uid !== authUserId) {
      const friendDocPromise = CacheService.getDocument<FriendModel>(
        session,
        `users/${authUserId}/friends/${uid}`
      );

      const [userDoc, friendDoc] = await Promise.all([userDocPromise, friendDocPromise]);
      if (userDoc && friendDoc) {
        return {
          ...userDoc,
          ...friendDoc,
        };
      } else if (userDoc) {
        return {
          ...userDoc,
          favorite: false,
        };
      }
    }

    const userDoc = await userDocPromise;
    return userDoc
      ? {
          ...userDoc,
          favorite: false,
        }
      : null;
  });
}

export const UserService = {
  getUser,
  getFriendUser,
} as const;
