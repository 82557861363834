import { AnyAction, Dispatch, Middleware } from '@reduxjs/toolkit';

import { RootState } from '../root-reducer';

type CrashReporterMiddleware = Middleware<unknown, RootState, Dispatch<AnyAction>>;

/**
 * Redux middleware to log unhandled exceptions thrown in actions and thunks
 */
// eslint-disable-next-line unicorn/consistent-function-scoping
export const crashReporterMiddleware: CrashReporterMiddleware = () => (next) => (action) => {
  try {
    const res = next(action);

    if (
      res?.meta?.requestStatus === 'rejected' &&
      !res?.meta?.aborted &&
      !res?.meta.rejectedWithValue
    ) {
      // TODO: log this somewhere
      console.error('Unhandled Redux Thunk Action Error', res.error);
    }
    return res;
  } catch (error) {
    // TODO: log this somewhere
    console.error('Unhandled Redux Action Error', error);
    throw error;
  }
};
