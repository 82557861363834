import _isUndefined from 'lodash/isUndefined';
import { useState } from 'react';

import { useConstant } from '@stur/hooks/use-constant';
import { Breakpoint, breakpoints, breakpointValues } from '@stur/models/breakpoint-model';

import { useEffectOnce } from './use-effect-once';

type Watcher = (event: MediaQueryListEvent) => void;
type WatcherMap = Map<MediaQueryList, Watcher>;
type BreakpointMap = Map<Breakpoint, boolean>;
type BreakpointCallback = (breakpoint: Breakpoint) => void;

const breakpointQueries: Record<Breakpoint, string | null> = {
  small: null,
  medium: `(min-width: ${breakpointValues.medium}px)`,
  large: `(min-width:  ${breakpointValues.large}px)`,
  xlarge: `(min-width:  ${breakpointValues.xlarge}px)`,
  xxlarge: `(min-width:  ${breakpointValues.xxlarge}px)`,
} as const;

function addMqListener(
  mql: MediaQueryList,
  listener: (event: MediaQueryListEvent) => void
): boolean {
  if (!_isUndefined(mql.addEventListener)) {
    mql.addEventListener('change', listener);
    return true;
  } else if (!_isUndefined(mql.addListener)) {
    mql.addListener(listener);
    return true;
  }

  return false;
}

function removeMqListener(
  mql: MediaQueryList,
  listener: (event: MediaQueryListEvent) => void
): boolean {
  if (!_isUndefined(mql.removeEventListener)) {
    mql.removeEventListener('change', listener);
    return true;
  } else if (!_isUndefined(mql.removeListener)) {
    mql.removeListener(listener);
    return true;
  }

  return false;
}

function initListeners(
  breakpointMap: BreakpointMap,
  onBreakpointChange: BreakpointCallback
): WatcherMap {
  let currentBreakpoint: Breakpoint;
  const watchers: WatcherMap = new Map();

  const updateBreakpoint = () => {
    const newBreakpoint = getCurrentBreakpoint(breakpointMap);
    if (newBreakpoint !== currentBreakpoint) {
      onBreakpointChange(newBreakpoint);
      currentBreakpoint = newBreakpoint;
    }
  };

  breakpoints.forEach((name: Breakpoint) => {
    const query = breakpointQueries[name];

    if (!query) {
      return;
    }

    const mql = window.matchMedia(query);

    // set initial value
    breakpointMap.set(name, mql.matches);

    // watch for changes
    const watcher: Watcher = (event: MediaQueryListEvent) => {
      if (breakpointMap.get(name) !== event.matches) {
        breakpointMap.set(name, event.matches);
        updateBreakpoint();
      }
    };

    if (addMqListener(mql, watcher)) {
      watchers.set(mql, watcher);
    }
  });

  updateBreakpoint();

  return watchers;
}

function removeListeners(watchers: WatcherMap): void {
  [...watchers.keys()].forEach((mql) => {
    const watcher = watchers.get(mql);

    if (!watcher) {
      return;
    }

    if (removeMqListener(mql, watcher)) {
      watchers.delete(mql);
    }
  });
}

function getCurrentBreakpoint(breakpointMap: BreakpointMap): Breakpoint {
  let bp: Breakpoint = 'small';

  breakpoints.forEach((key) => {
    if (breakpointMap.get(key)) {
      bp = key;
    }
  });

  return bp;
}

/**
 * Calls the onBreakpointChange callback whenever the current breakpoint changes
 * Performs automatic cleanup on media query listeners
 */
export function useMediaQueryListeners(onBreakpointChange: BreakpointCallback): boolean {
  const [initialized, setInitialized] = useState(false);
  const breakpointMap: BreakpointMap = useConstant(
    () =>
      new Map([
        ['small', true], // always true
        ['medium', false],
        ['large', false],
        ['xlarge', false],
        ['xxlarge', false],
      ])
  );

  // use empty deps to ensure initialization only happens once when the container is initialized
  useEffectOnce(() => {
    if (!window?.matchMedia) {
      return;
    }

    const watchers = initListeners(breakpointMap, onBreakpointChange);
    setInitialized(true);
    return () => removeListeners(watchers);
  });

  // containing component should wait until initialized is true before rendering
  // to give the effect a chance to run onece and set the initial breakpoint correctly
  return initialized;
}
