import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _uniqBy from 'lodash/uniqBy';

import { CollectionModel } from '@stur/models/collection-model';
import { EventAccessRequestModel } from '@stur/models/event-access-request-model';
import { EventModel, EventPollModel } from '@stur/models/event-model';
import { EventParticipantResponse } from '@stur/models/event-participant-model';
import { EventService, ListEventsResponse } from '@stur/services/event-service';
import { AuthSelectors } from '@stur/store/auth/auth-selectors';
import { AsyncThunkConfig } from '@stur/store/store';
import { ReduxUtils } from '@stur/utils/redux-utils';
import { RoutingUtils } from '@stur/utils/routing-utils';

import { AuthActions } from '../auth/auth-reducer';
import { NotificationActions } from '../notification/notification-reducer';
import { EventSelectors } from './event-selectors';
import { EventState } from './event-state';

const initialState: EventState = {
  eventDetail: null,
  eventParticipants: [],
  eventPolls: [],
  eventPollVoters: {},
  eventList: null,
  hasMoreEvents: false,
};

export interface ListEventsPayload {
  append: boolean;
}
const listEvents = createAsyncThunk<ListEventsResponse | null, ListEventsPayload, AsyncThunkConfig>(
  'event/listEvents',
  async (request, thunkApi) => {
    const { append } = request;
    const state = thunkApi.getState();
    const currentUser = AuthSelectors.getCurrentUser(state);
    const eventList = EventSelectors.getEventList(state);
    const hasMoreEvents = EventSelectors.hasMoreEvents(state);

    if (!currentUser) {
      throw new Error('Not logged in');
    }

    const startAfter =
      append && hasMoreEvents && eventList ? eventList[eventList.length - 1]?.id : undefined;
    const listResult = await EventService.listEvents({
      uid: currentUser.uid,
      startAfter,
    });

    if (!listResult) {
      return null;
    }

    return {
      events:
        append && eventList
          ? _uniqBy(eventList.concat(listResult.events), 'id')
          : listResult.events,
      hasMore: listResult.hasMore,
    };
  }
);

export interface GetEventPayload {
  eventId: string;
}
const getEvent = createAsyncThunk<EventModel | null, GetEventPayload, AsyncThunkConfig>(
  'event/getEvent',
  async (request, thunkApi) => {
    const event = await EventService.getEvent(request);
    return event;
  }
);

export interface GetEventPollsPayload {
  eventId: string;
}
const getEventPolls = createAsyncThunk<
  CollectionModel<EventPollModel> | null,
  GetEventPollsPayload,
  AsyncThunkConfig
>('event/getEventPolls', async (request, thunkApi) => {
  const event = await EventService.getEventPolls(request);
  return event;
});

export interface SetResponsePayload {
  response: EventParticipantResponse;
}
export interface SetResponseFulfilled {
  response: EventParticipantResponse;
  userId: string;
  accessRequest: EventAccessRequestModel | null;
}
const setResponse = createAsyncThunk<SetResponseFulfilled, SetResponsePayload, AsyncThunkConfig>(
  'event/setResponse',
  async (request, thunkApi) => {
    const { response } = request;
    const state = thunkApi.getState();
    const event = EventSelectors.getEventDetail(state);
    const currentUser = AuthSelectors.getCurrentUser(state);
    const isParticipant = EventSelectors.isParticipant(state);

    if (!event?.id) {
      throw new Error('Event not loaded');
    }

    if (!currentUser?.uid) {
      throw new Error('Not logged in');
    }

    if (currentUser?.uid === event.host) {
      thunkApi.dispatch(
        NotificationActions.warning({
          title: 'Warning',
          message: "You're the host of this event, no need to RSVP",
        })
      );
      await RoutingUtils.clearQueryParams();
      return thunkApi.rejectWithValue(null);
    }

    const validRsvps: EventParticipantResponse[] = ['yes', 'no', 'tbd'];
    if (!validRsvps.includes(response)) {
      await RoutingUtils.clearQueryParams();
      return thunkApi.rejectWithValue(null);
    }

    let accessRequest: EventAccessRequestModel | null = null;
    if (isParticipant) {
      await EventService.setResponse({ eventId: event.id, userId: currentUser.uid, response });
    } else {
      accessRequest = await EventService.requestResponse({
        eventId: event.id,
        userId: currentUser.uid,
        response,
      });
    }
    await RoutingUtils.clearQueryParams();
    return { response, userId: currentUser.uid, accessRequest };
  }
);

export interface GetAccessRequestPayload {
  eventId: string;
}
const getAccessRequest = createAsyncThunk<
  EventAccessRequestModel | undefined,
  GetAccessRequestPayload,
  AsyncThunkConfig
>('event/getAccessRequest', async (request, thunkApi) => {
  const { eventId } = request;
  const state = thunkApi.getState();
  const userId = AuthSelectors.getCurrentUserId(state);
  const accessRequest = await EventService.getAccessRequest({ userId, eventId });
  return accessRequest || undefined;
});

const observeEventPollVoters = ReduxUtils.createObserverThunk(
  'event/observeEventPollVoters',
  EventService.observeEventPollVoters
);

export interface SetPollVotesPayload {
  pollId: string;
  votes: string[];
}
export interface SetPollVotesResponse {
  accessRequest: EventAccessRequestModel | null;
}
const setPollVotes = createAsyncThunk<SetPollVotesResponse, SetPollVotesPayload, AsyncThunkConfig>(
  'event/setPollVotes',
  async (request, thunkApi) => {
    const { pollId, votes } = request;
    const state = thunkApi.getState();
    const event = EventSelectors.getEventDetail(state);
    const currentUser = AuthSelectors.getCurrentUser(state);
    const polls = EventSelectors.getEventPolls(state);
    const isParticipant = EventSelectors.isParticipant(state);

    if (!event?.id) {
      throw new Error('Event not loaded');
    }

    if (!currentUser?.uid) {
      throw new Error('Not logged in');
    }

    if (!Array.isArray(votes)) {
      throw new TypeError('Votes must be an array');
    }

    const poll = polls.find((p) => p._id === pollId);
    if (!poll) {
      await RoutingUtils.clearQueryParams();
      return thunkApi.rejectWithValue(null);
    }

    let accessRequest: EventAccessRequestModel | null = null;
    const validVotes = votes.filter((vote) => !!poll.options[vote]);
    if (isParticipant) {
      await EventService.setPollVotes({
        eventId: event.id,
        userId: currentUser.uid,
        pollId,
        votes: validVotes,
      });
    } else {
      accessRequest = await EventService.requestPollVotes({
        eventId: event.id,
        userId: currentUser.uid,
        pollId,
        votes: validVotes,
      });
    }
    await RoutingUtils.clearQueryParams();
    return { accessRequest };
  }
);

const observeEventParticipants = ReduxUtils.createObserverThunk(
  'event/observeEventParticipants',
  EventService.observeEventParticipants
);

/**
 * Event Slice
 */
const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    clearEventList: (state) => {
      state.eventList = initialState.eventList;
      state.hasMoreEvents = initialState.hasMoreEvents;
    },
    clearEvent: (state) => {
      state.eventDetail = initialState.eventDetail;
      state.eventAccessRequest = initialState.eventAccessRequest;
      state.eventParticipants = initialState.eventParticipants;
      state.eventPolls = initialState.eventPolls;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AuthActions.completeLogout.fulfilled, (state) => {
      Object.assign(state, {
        ...initialState,

        // don't reset event detail - it is available to logged out users as well
        eventDetail: state.eventDetail,
        eventParticipants: state.eventParticipants,
        eventPolls: state.eventParticipants,
      });
    });

    builder.addCase(getEvent.pending, (state, { payload }) => {
      state.eventDetail = null;
    });
    builder.addCase(getEvent.fulfilled, (state, { payload }) => {
      state.eventDetail = payload;
    });

    builder.addCase(setResponse.fulfilled, (state, { payload }) => {
      if (payload.accessRequest) {
        state.eventAccessRequest = payload.accessRequest;
      }
    });

    builder.addCase(getEventPolls.fulfilled, (state, { payload }) => {
      state.eventPolls = payload || [];
    });

    builder.addCase(listEvents.fulfilled, (state, { payload }) => {
      if (payload) {
        state.eventList = payload.events;
        state.hasMoreEvents = payload.hasMore;
      }
    });

    builder.addCase(getAccessRequest.fulfilled, (state, { payload }) => {
      state.eventAccessRequest = payload;
    });
    builder.addCase(setPollVotes.fulfilled, (state, { payload }) => {
      if (payload.accessRequest) {
        state.eventAccessRequest = payload.accessRequest;
      }
    });

    builder.addCase(observeEventPollVoters.fulfilled, (state, { payload, meta, type }) => {
      state.eventPollVoters[meta.arg.pollId] = ReduxUtils.mergeObservedState(
        payload,
        state.eventPollVoters[meta.arg.pollId]
      );
    });

    builder.addCase(observeEventParticipants.fulfilled, (state, { payload }) => {
      state.eventParticipants = ReduxUtils.mergeObservedState(payload, state.eventParticipants);
    });
  },
});

export const EventActions = {
  ...eventSlice.actions,
  getAccessRequest,
  getEvent,
  getEventPolls,
  listEvents,
  observeEventParticipants,
  observeEventPollVoters,
  setPollVotes,
  setResponse,
} as const;

export default eventSlice.reducer;
