import { createSlice } from '@reduxjs/toolkit';

import { ReduxUtils } from '@stur/utils/redux-utils';

import { BrowserState } from './browser-state';

const initialState: BrowserState = {
  isBrowser: false,
  breakpoint: 'small',
};

const browserSlice = createSlice({
  name: 'browser',
  initialState,
  reducers: {
    setBreakpoint: ReduxUtils.makeSetter('breakpoint'),
    setisBrowser: ReduxUtils.makeSetter('isBrowser'),
  },
});

export const BrowserActions = {
  ...browserSlice.actions,
} as const;

export default browserSlice.reducer;
