import type firebase from 'firebase';

import { ServerValidationError } from '@stur/errors/server-validation-error';
import { AuthProvider } from '@stur/models/auth-provider-model';
import { AuthUserModel } from '@stur/models/auth-user-model';
import { gradientTypes } from '@stur/models/gradient-model';
import { LinkAccountModel } from '@stur/models/link-account-model';
import { UserModel } from '@stur/models/user-model';
import { ObjectUtils } from '@stur/utils/object-utils';

import { FirebaseService } from './firebase-service';

export interface CompleteAccountRequest {
  firstName: string;
  lastName: string;
  username: string;
}

export interface CompleteLoginRequest {
  uid: string;
}

export interface LoginWithEmailRequest {
  email: string;
  password: string;
}

export interface ResetPasswordRequest {
  email: string;
  returnUrl: string;
}

/**
 * After authenticating using any method, fetch full user profile from the DB
 */
async function completeLogin(request: CompleteLoginRequest): Promise<UserModel | null> {
  return FirebaseService.withSession(async ({ app }) => {
    const snapshot = await app.firestore().doc(`/users/${request.uid}`).get();
    if (snapshot.exists) {
      const authUser = snapshot.data() as UserModel;
      return authUser;
    }

    return null;
  });
}

/**
 * Attempt to authenticate using email + password (log in only)
 */
async function logInWithEmail(
  request: LoginWithEmailRequest
): Promise<firebase.auth.UserCredential | null> {
  return FirebaseService.withSession(async ({ app }) => {
    const { email, password } = request;
    const result = await app.auth().signInWithEmailAndPassword(email, password);
    return result;
  });
}

/**
 * Create an account using email + password
 */
async function createAccountWithEmail(
  request: LoginWithEmailRequest
): Promise<firebase.auth.UserCredential | null> {
  return FirebaseService.withSession(async ({ app }) => {
    const { email, password } = request;
    const result = await app.auth().createUserWithEmailAndPassword(email, password);
    await result.user?.sendEmailVerification();
    return result;
  });
}

/**
 * Attempt to authenticate using Facebook (log in or create account)
 */
async function logInWithFacebook(): Promise<firebase.auth.UserCredential | null> {
  return FirebaseService.withSession(async ({ app, facebookAuthProvider }) => {
    facebookAuthProvider.setCustomParameters({
      display: 'popup',
    });
    facebookAuthProvider.addScope('public_profile');
    facebookAuthProvider.addScope('email');

    const result = await app.auth().signInWithPopup(facebookAuthProvider);
    return result;
  });
}

/**
 * Attempt to authenticate using Google (log in or create account)
 */
async function logInWithGoogle(): Promise<firebase.auth.UserCredential | null> {
  return FirebaseService.withSession(async ({ app, googleAuthProvider }) => {
    googleAuthProvider.setCustomParameters({
      display: 'popup',
    });
    googleAuthProvider.addScope('profile');
    googleAuthProvider.addScope('email');

    const result = await app.auth().signInWithPopup(googleAuthProvider);
    return result;
  });
}

/**
 * Links the currently authenticated user to an existing account using a saved credential
 */
async function linkAccount(
  account: LinkAccountModel
): Promise<firebase.auth.UserCredential | null> {
  return FirebaseService.withSession(async ({ app, lib }) => {
    const currentUser = app.auth().currentUser;

    if (!currentUser) {
      throw new Error('Must be logged in to link accounts');
    }

    let credential: firebase.auth.AuthCredential | null = null;

    switch (account.signInProvider) {
      case 'email':
        credential = lib.auth.EmailAuthProvider.credential(
          account.email,
          account.password as string
        );
        break;
      case 'facebook':
        credential = lib.auth.FacebookAuthProvider.credential(account.accessToken as string);
        break;
      case 'google':
        credential = lib.auth.GoogleAuthProvider.credential(
          account.idToken,
          account.accessToken as string
        );
        break;
      default:
        break;
    }

    if (!credential) {
      throw new Error('Count not load credential');
    }

    const result = await app.auth().currentUser?.linkWithCredential(credential);

    if (!result) {
      throw new Error('Failed to link accounts');
    }

    return result;
  });
}

/**
 * Log out
 *
 * @returns boolean indicating if the firebase signOut call was completed
 */
async function logOut(): Promise<void | null> {
  return FirebaseService.withSession(async ({ app }) => {
    await app.auth().signOut();
  });
}

/**
 * Initiate a password reset
 */
async function resetPassword(request: ResetPasswordRequest): Promise<void | null> {
  return FirebaseService.withSession(async ({ app }) => {
    const { email, returnUrl } = request;
    try {
      await app.auth().sendPasswordResetEmail(email, {
        url: returnUrl,
        handleCodeInApp: false,
      });
    } catch (error) {
      console.error('reset password error', error);
      throw error;
    }
  });
}

/**
 * Store the authenticated user into the users database with their preferred name & username
 *
 * Requires active firebase auth session!
 */
async function completeAccount(request: CompleteAccountRequest): Promise<UserModel | null> {
  return FirebaseService.withSession(async ({ app }) => {
    // TODO: custom error types

    const currentUser = app.auth().currentUser;

    if (!currentUser) {
      throw new Error('Not logged in');
    }

    const { username, firstName, lastName } = request;
    const { uid, email, photoURL } = currentUser;

    if (!email) {
      throw new Error('User email not defined');
    }

    const userDocRef = app.firestore().doc(`users/${uid}`);
    const usernameDocRef = app.firestore().doc(`usernames/${username.toLowerCase()}`);

    const newUser: UserModel = {
      uid,
      username,
      firstName,
      lastName,
      email,
      photoURL: photoURL || '',
      profileTheme: gradientTypes[Math.floor(Math.random() * gradientTypes.length)],
    };

    await app.firestore().runTransaction(async (transaction) => {
      const snapshotUsername = await transaction.get(usernameDocRef);
      if (!snapshotUsername.exists) {
        transaction.set(userDocRef, ObjectUtils.omitUndefined(newUser));
        transaction.set(usernameDocRef, { uid });
      } else {
        throw new ServerValidationError('auth/username-exists');
      }
    });

    return newUser;
  });
}

/**
 * Extract relevant user info from social auth meta
 * This is useful for pre-populating fields during the "complete account" step
 */
function getAuthUserFromCredential(
  userCredential: firebase.auth.UserCredential | null
): AuthUserModel | null {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const profile = userCredential?.additionalUserInfo?.profile as Record<string, any> | undefined;
  const providerId = userCredential?.additionalUserInfo?.providerId || '';
  const nameParts = userCredential?.user?.displayName?.split(' ') || [];
  const provider = AuthProvider.fromSignInMethod(providerId);

  switch (provider) {
    case 'google':
      return {
        email: profile?.email,
        firstName: profile?.given_name,
        lastName: profile?.family_name,
        profilePictureUrl: profile?.picture,
      };
    case 'facebook':
      return {
        email: profile?.email,
        firstName: profile?.first_name,
        lastName: profile?.last_name,
        profilePictureUrl: profile?.picture?.data?.url,
      };
    case 'email':
      return {
        email: userCredential?.user?.email ?? undefined,
        firstName: nameParts.length > 0 ? nameParts[0] : undefined,
        lastName: nameParts.length > 1 ? nameParts.slice(1).join(' ') : undefined,
        profilePictureUrl: userCredential?.user?.photoURL ?? undefined,
      };
  }

  return null;
}

/**
 * Get a list of auth providers for a given user email
 */
async function getSignInMethods(email: string): Promise<AuthProvider[] | null> {
  return FirebaseService.withSession(async ({ app }) => {
    const methods = await app.auth().fetchSignInMethodsForEmail(email);
    return methods
      .map((method) => AuthProvider.fromSignInMethod(method))
      .filter((method) => !!method) as AuthProvider[];
  });
}

export const AuthService = {
  completeAccount,
  completeLogin,
  createAccountWithEmail,
  getAuthUserFromCredential,
  getSignInMethods,
  linkAccount,
  logInWithEmail,
  logInWithFacebook,
  logInWithGoogle,
  logOut,
  resetPassword,
} as const;
