import { AnyAction, createAction, createReducer } from '@reduxjs/toolkit';

import { ActionStatusState, ThunkStatus, validThunkStatus } from './action-status-state';

const initialState: ActionStatusState = {
  actions: {},
  inFlight: {},
};

function getThunkStatus(action: AnyAction): ThunkStatus | null {
  const status = action?.meta?.requestStatus;

  if (status && validThunkStatus.includes(status)) {
    return status;
  }

  return null;
}

function getThunkTypePrefix(actionName: string): string {
  const parts = actionName.split('/');
  parts.pop();
  return parts.join('/');
}

const resetAction = createAction('actionStatus/reset');

export default createReducer(initialState, (builder) => {
  builder
    .addCase(resetAction, () => initialState)
    .addMatcher(
      (action) => !!getThunkStatus(action),
      (state, action) => {
        const thunkStatus = getThunkStatus(action);
        const typePrefix = getThunkTypePrefix(action.type);

        if (thunkStatus) {
          const inFlight = state.inFlight[typePrefix] || {};

          if (thunkStatus === 'pending') {
            inFlight[action.meta.requestId] = true;
          } else {
            delete inFlight[action.meta.requestId];
          }

          const hasInFlightRequests = Object.keys(inFlight).length > 0;

          state.inFlight[typePrefix] = inFlight;
          state.actions[typePrefix] = hasInFlightRequests ? 'pending' : thunkStatus;
        }
      }
    );
});

export const ActionStatusActions = {
  resetAction,
} as const;
