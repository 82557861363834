import _isUndefined from 'lodash/isUndefined';
import _omitBy from 'lodash/omitBy';

/**
 * Returns a shallow copy of the object without null or undefined props
 */
// eslint-disable-next-line @typescript-eslint/ban-types
function omitUndefined<T>(obj: Record<string, T> | null | undefined): Record<string, T> {
  return _omitBy(obj, _isUndefined);
}

export const ObjectUtils = {
  omitUndefined,
} as const;
