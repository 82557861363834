import React, { ReactElement, RefObject } from 'react';
import { Transition } from 'react-transition-group';
import { TransitionProps } from 'react-transition-group/Transition';
import { POSITIONS, Notification } from 'reapop';

export type ToastTransitionProps = {
  notification: Notification;
  duration?: number;
} & Omit<TransitionProps<HTMLElement>, 'addEndListener'>;

export const ToastTransition = (props: ToastTransitionProps): ReactElement => {
  const duration = props.duration || 450;
  const hideDuration = 200;
  const colapseAnimationDuration = 300;
  const { children, notification, nodeRef, ...otherProps } = props;
  const getNode = () => (nodeRef as RefObject<HTMLElement>).current as HTMLElement;
  const transformDirection = ([POSITIONS.topCenter, POSITIONS.bottomCenter] as string[]).includes(
    notification.position
  )
    ? 'translateY'
    : 'translateX';
  const transformValue = ([
    POSITIONS.topCenter,
    POSITIONS.topLeft,
    POSITIONS.bottomLeft,
  ] as string[]).includes(notification.position)
    ? '-100%'
    : '100%';

  const animationProps: KeyframeAnimationOptions = {
    fill: 'forwards',
  };
  const onEnter = () => {
    getNode().animate(
      [
        { transform: `${transformDirection}(${transformValue})`, opacity: 0 },
        { transform: `${transformDirection}(0)`, opacity: 1 },
      ],
      {
        ...animationProps,
        duration,
        easing: 'cubic-bezier(.36,1.23,.62,1)',
      }
    );
  };
  const onExit = () => {
    const node = getNode();
    node.animate([{ transform: `${transformDirection}(${transformValue})`, opacity: 0 }], {
      ...animationProps,
      duration: hideDuration,
      easing: 'ease-in-out',
    });
    setTimeout(() => {
      const height = node.offsetHeight;
      node.animate(
        [
          { maxHeight: `${height}px`, opacity: 0 },
          { margin: 0, maxHeight: 0 },
        ],
        {
          ...animationProps,
          duration: colapseAnimationDuration,
          easing: 'ease-in-out',
        }
      );
    }, hideDuration);
  };

  return (
    <Transition
      nodeRef={nodeRef}
      onEnter={onEnter}
      onExit={onExit}
      timeout={duration + colapseAnimationDuration}
      {...otherProps}
    >
      {children}
    </Transition>
  );
};
