export type GradientType = 'black' | 'mint' | 'peach' | 'ruby' | 'sunset' | 'synthwave';

export const gradientTypes: GradientType[] = [
  'ruby',
  'synthwave',
  'peach',
  'mint',
  'sunset',
  'black',
];

export const gradientColors: Record<GradientType, string[]> = {
  black: ['#000000', '#000000'],
  mint: ['#5193CD', '#66C187'],
  peach: ['#DD788A', '#FD9D57'],
  ruby: ['#261C3B', '#FF0566'],
  sunset: ['#FB6624', '#C71E35'],
  synthwave: ['#DD788A', '#C41C76'],
};
