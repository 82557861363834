import { MappedError, MappedErrorData } from './mapped-error';

export type ServerValidationErrorCode = 'auth/username-exists';

export interface ServerValidationErrorData extends MappedErrorData {}

const messages: Record<ServerValidationErrorCode, string> = {
  'auth/username-exists': 'This username is taken',
};

/**
 * Intended for consumption by form Components to display inline server errors on specific fields
 * The generic type T should be the type of the request object or form data object that generated the error
 */
export interface ServerValidationErrorMapping<T> {
  readonly field?: keyof T;
  readonly code: ServerValidationErrorCode;
  readonly message: string;
}

/**
 * Server validation errors have unique codes that can be mapped to user friendly messages
 */
export class ServerValidationError extends MappedError<
  ServerValidationErrorCode,
  ServerValidationErrorData
> {
  constructor(
    code: ServerValidationErrorCode,
    data?: ServerValidationErrorData,
    ...causes: Error[]
  ) {
    super(code, data, messages, ...causes);
  }

  public mapToField<T>(field: keyof T): ServerValidationErrorMapping<T> {
    const { code, message } = this;
    return {
      code,
      message,
      field,
    };
  }
}
