import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type firebase from 'firebase';
import { navigate } from 'gatsby';
import ChainedError from 'typescript-chained-error';

import { ServerValidationError } from '@stur/errors/server-validation-error';
import { AuthProvider } from '@stur/models/auth-provider-model';
import { AuthUserModel } from '@stur/models/auth-user-model';
import { LinkAccountModel } from '@stur/models/link-account-model';
import { UserModel } from '@stur/models/user-model';
import {
  AuthService,
  CompleteAccountRequest,
  CompleteLoginRequest,
  LoginWithEmailRequest,
  ResetPasswordRequest,
} from '@stur/services/auth-service';
import { CacheService } from '@stur/services/cache-service';
import { FirebaseService } from '@stur/services/firebase-service';
import { NotificationActions } from '@stur/store/notification/notification-reducer';
import { AsyncThunkConfig } from '@stur/store/store';
import { DOMUtils } from '@stur/utils/dom-utils';
import { RoutingUtils } from '@stur/utils/routing-utils';

import { AuthSelectors } from './auth-selectors';
import { AuthState, AuthErrors } from './auth-state';

export interface LoginPayload {
  provider: AuthProvider;
  credentials?: LoginWithEmailRequest;
}

const initialState: AuthState = {
  isInitialized: false,
  isLoggedIn: false,
  authUser: null,
  currentUser: null,
  linkAccount: null,
  errors: {},
};

/**
 * Complete account
 * Save the user info to the database
 */
const completeAccount = createAsyncThunk<
  UserModel | null,
  CompleteAccountRequest,
  AsyncThunkConfig<AuthErrors['completeAccount']>
>('auth/completeAccount', async (request, thunkApi) => {
  let user: UserModel | null = null;

  try {
    user = await AuthService.completeAccount(request);
  } catch (error) {
    if (error instanceof ServerValidationError && error.code === 'auth/username-exists') {
      return thunkApi.rejectWithValue([error.mapToField('username')]);
    }

    thunkApi.dispatch(
      NotificationActions.error({
        title: 'Error',
        message: 'There was a problem saving your account information, please try again later.',
      })
    );
    throw new ChainedError('AuthReducer.createAccount', error);
  }

  if (user) {
    await navigate(RoutingUtils.getRouteOrReturnPath(RoutingUtils.routes.dashboard()));
  }
  return user;
});

/**
 * Log In
 * Attempt to establish an auth session
 * If the account exists using a different credential, it will reject with a "link account" payload
 * If successful, it will trigger a firebase onAuthStateChanged event and then we'll dispatch a completeLogin
 */
const logIn = createAsyncThunk<
  AuthUserModel | null,
  LoginPayload,
  AsyncThunkConfig<LinkAccountModel | null>
>('auth/logIn', async (request, thunkApi) => {
  try {
    let userCredential: firebase.auth.UserCredential | null = null;

    // log in with provider
    switch (request.provider) {
      case 'email':
        if (!request.credentials) {
          throw new Error('Credentials are required for email provider');
        }
        userCredential = await AuthService.logInWithEmail(request.credentials);
        break;
      case 'facebook':
        userCredential = await AuthService.logInWithFacebook();
        break;
      case 'google':
        userCredential = await AuthService.logInWithGoogle();
        break;
      default:
        throw new Error('Invalid auth provider');
    }

    return AuthService.getAuthUserFromCredential(userCredential);
  } catch (error) {
    switch (error?.code) {
      // popup dismissed: fail quietly
      case 'auth/popup-closed-by-user':
        return thunkApi.rejectWithValue(null);

      // account exists: attempt link
      case 'auth/account-exists-with-different-credential':
        try {
          const availableProviders = await AuthService.getSignInMethods(error.email);
          const signInProvider = AuthProvider.fromSignInMethod(error.credential.signInMethod);

          if (!signInProvider) {
            throw new Error('Unexpected sign in method');
          }

          await navigate(RoutingUtils.routes.linkAccount());
          return thunkApi.rejectWithValue({
            email: error.email,
            accessToken: error.credential.accessToken,
            idToken: error.credential.idToken,
            signInProvider,
            availableProviders: availableProviders || [],
          });
        } catch (error) {
          throw new ChainedError('AuthReducer.login - get sign in methods', error);
        }

      // not found: display toast
      case 'auth/user-not-found':
        thunkApi.dispatch(
          NotificationActions.warning({
            title: 'Error',
            message: 'We were unable to find an account associated with that email.',
          })
        );
        return thunkApi.rejectWithValue(null);

      case 'auth/wrong-password':
        thunkApi.dispatch(
          NotificationActions.warning({
            title: 'Error',
            message: 'Invalid username or password',
          })
        );
        return thunkApi.rejectWithValue(null);

      // unexpected error: display toast
      default:
        thunkApi.dispatch(
          NotificationActions.error({
            title: 'Error',
            message: 'We are unable to log you in at this time, please try again later.',
          })
        );
        throw new ChainedError('AuthReducer.login', error);
    }
  }
});

/**
 * Create account with email and password
 */
const createAccountWithEmail = createAsyncThunk<
  AuthUserModel | null,
  LoginWithEmailRequest,
  AsyncThunkConfig<LinkAccountModel | null>
>('auth/createAccountWithEmail', async (request, thunkApi) => {
  try {
    const userCredential = await AuthService.createAccountWithEmail(request);
    return AuthService.getAuthUserFromCredential(userCredential);
  } catch (error) {
    switch (error?.code) {
      case 'auth/email-already-in-use':
        try {
          const availableProviders = await AuthService.getSignInMethods(request.email);

          await navigate(RoutingUtils.routes.linkAccount());
          return thunkApi.rejectWithValue({
            email: request.email,
            password: request.password,
            signInProvider: 'email',
            availableProviders: availableProviders || [],
          });
        } catch (error) {
          throw new ChainedError('AuthReducer.login - get sign in methods', error);
        }

      default:
        thunkApi.dispatch(
          NotificationActions.error({
            title: 'Error',
            message: 'We are unable to create your account at this time, please try again later.',
          })
        );
        throw new ChainedError('AuthReducer.createAccountWithEmail', error);
    }
  }
});

/**
 * Complete login
 * Loads the current user data from the DB
 * If user data isn't found, proceed with the "complete account" flow
 */
const completeLogin = createAsyncThunk<UserModel | null, CompleteLoginRequest, AsyncThunkConfig>(
  'auth/completeLogin',
  async (request, thunkApi) => {
    let user: UserModel | null = null;

    const account = AuthSelectors.getLinkAccount(thunkApi.getState());
    if (account) {
      try {
        const linkResult = await AuthService.linkAccount(account);
        if (linkResult) {
          thunkApi.dispatch(
            NotificationActions.success({
              title: 'Success',
              message: 'Your accounts have been linked.',
            })
          );
        }
      } catch (error) {
        thunkApi.dispatch(
          NotificationActions.error({
            title: 'Error',
            message: 'There was a problem linking your accounts, please try again later.',
          })
        );
        throw new ChainedError('AuthReducer.completeLogin - link accounts', error);
      }
    }

    try {
      user = await AuthService.completeLogin(request);
    } catch (error) {
      thunkApi.dispatch(
        NotificationActions.error({
          title: 'Error',
          message: 'There was a problem logging you in, please try again later.',
        })
      );
      throw new ChainedError('AuthReducer.completeLogin', error);
    }

    if (user) {
      if (!DOMUtils.isClientOnlyPage()) {
        await navigate(RoutingUtils.getRouteOrReturnPath(RoutingUtils.routes.dashboard()));
      }
      return user;
    } else {
      await navigate(RoutingUtils.routes.completeAccount());
      return user;
    }
  }
);

/**
 * Log out
 */
const logOut = createAsyncThunk<void, void, AsyncThunkConfig>(
  'auth/logOut',
  async (_, thunkApi) => {
    let logoutError: Error | undefined;

    try {
      await AuthService.logOut();
    } catch (error) {
      logoutError = error;
    }

    if (logoutError) {
      // in case of error, ensure completeLogout is still called to update the store
      thunkApi.dispatch(AuthActions.completeLogout());
      throw new ChainedError('AuthReducer.createAccountWithEmail', logoutError);
    }
  },
  {
    condition: (arg, thunkApi) =>
      thunkApi.getState().actionStatus.actions['auth/logOut'] !== 'pending',
  }
);

const completeLogout = createAsyncThunk<void, void, AsyncThunkConfig>(
  'auth/completeLogout',
  async (_, thunkApi) => {
    const isLoggedIn = AuthSelectors.isLoggedIn(thunkApi.getState());
    if (!isLoggedIn) {
      return;
    }

    await CacheService.clearAll();
    await FirebaseService.endSession();
  }
);

/**
 * Reset password
 */
const resetPassword = createAsyncThunk<void, Pick<ResetPasswordRequest, 'email'>, AsyncThunkConfig>(
  'auth/resetPassword',
  async ({ email }, thunkApi) => {
    try {
      await AuthService.resetPassword({
        email,
        returnUrl: RoutingUtils.getAbsoluteUrl(RoutingUtils.routes.logIn()),
      });

      thunkApi.dispatch(
        NotificationActions.success({
          title: 'Email Sent',
          message: `Please check your email at ${email} for instructions on resetting your password.`,
          manualDismiss: true,
        })
      );

      await navigate(RoutingUtils.routes.logIn());
    } catch (error) {
      if (error?.code === 'auth/user-not-found') {
        thunkApi.dispatch(
          NotificationActions.warning({
            title: 'Error',
            message: 'We were unable to find an account associated with that email.',
          })
        );
        return thunkApi.rejectWithValue(null);
      } else {
        thunkApi.dispatch(
          NotificationActions.error({
            title: 'Error',
            message: 'We are unable to reset your password at this time, please try again later.',
          })
        );
        throw new ChainedError('AuthReducer.resetPassword', error);
      }
    }
  }
);

/**
 * Auth Slice
 */
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createAccountWithEmail.fulfilled, (state, { payload }) => {
      state.authUser = payload;
    });
    builder.addCase(createAccountWithEmail.rejected, (state, { payload }) => {
      state.linkAccount = payload || null;
    });

    builder.addCase(logIn.fulfilled, (state, { payload }) => {
      state.authUser = payload;
    });
    builder.addCase(logIn.rejected, (state, { payload }) => {
      state.linkAccount = payload || null;
    });

    builder.addCase(completeLogin.fulfilled, (state, { payload }) => {
      state.currentUser = payload;
      state.linkAccount = null;
      state.isInitialized = true;
      state.isLoggedIn = true;
    });

    builder.addCase(completeAccount.fulfilled, (state, { payload }) => {
      state.currentUser = payload;
      state.errors.completeAccount = undefined;
    });
    builder.addCase(completeAccount.rejected, (state, { payload }) => {
      state.errors.completeAccount = payload;
    });

    builder.addCase(completeLogout.fulfilled, (state) => {
      Object.assign(state, initialState);
      state.isInitialized = true;
      state.isLoggedIn = false;
    });
  },
});

export const AuthActions = {
  ...authSlice.actions,
  completeAccount,
  completeLogin,
  completeLogout,
  createAccountWithEmail,
  logIn,
  logOut,
  resetPassword,
} as const;

export default authSlice.reducer;
