import { AnyAction, CombinedState, combineReducers, Reducer } from '@reduxjs/toolkit';

import actionStatus from './action-status/action-status-reducer';
import { ActionStatusState } from './action-status/action-status-state';
import auth from './auth/auth-reducer';
import { AuthState } from './auth/auth-state';
import browser from './browser/browser-reducer';
import { BrowserState } from './browser/browser-state';
import event from './event/event-reducer';
import { EventState } from './event/event-state';
import notifications from './notification/notification-reducer';
import { NotificationState } from './notification/notification-state';
import user from './user/user-reducer';
import { UserState } from './user/user-state';

export interface RootState {
  actionStatus: ActionStatusState;
  auth: AuthState;
  browser: BrowserState;
  event: EventState;
  notifications: NotificationState;
  user: UserState;
}

const rootReducer: Reducer<CombinedState<RootState>, AnyAction> = combineReducers<RootState>({
  actionStatus,
  auth,
  browser,
  event,
  notifications,
  user,
});

export default rootReducer;
