import _camelCase from 'lodash/camelCase';

function modifierClass<Styles extends Record<string, string>, Modifiers extends string>(
  styles: Styles,
  element: keyof Styles,
  modifier?: Modifiers
): keyof Styles | undefined {
  if (!modifier) {
    return;
  }

  return styles[_camelCase(`${element}--${modifier}`) as keyof Styles];
}

export const StyleUtils = {
  modifierClass,
} as const;
