import _invert from 'lodash/invert';

export type AuthProvider = 'email' | 'facebook' | 'google' | 'apple';

const methodMap: Record<AuthProvider, string> = {
  email: 'password',
  facebook: 'facebook.com',
  google: 'google.com',
  apple: 'apple.com',
};
const providerMap = _invert(methodMap) as Record<string, AuthProvider>;

function fromSignInMethod(method: string): AuthProvider | undefined {
  return providerMap[method];
}

function toSignInMethod(provider: AuthProvider): string {
  return methodMap[provider];
}

export const AuthProvider = {
  toSignInMethod,
  fromSignInMethod,
} as const;
