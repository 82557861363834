import { createSelector } from '@reduxjs/toolkit';
import { createStructuredSelector } from 'reselect';

import { CollectionItemModel } from '@stur/models/collection-model';
import { EventAccessRequestModel } from '@stur/models/event-access-request-model';
import {
  EventLocationPollModel,
  EventModel,
  EventPollModel,
  EventTimePollModel,
} from '@stur/models/event-model';
import {
  EventParticipantModel,
  EventParticipantResponse,
} from '@stur/models/event-participant-model';
import { EventPollVoterModel } from '@stur/models/event-poll-voter-model';
import { EventPollVotesModel } from '@stur/models/event-poll-votes-model';
import { ObservedCollectionModel } from '@stur/models/observed-model';
import { RootState } from '@stur/store/root-reducer';

import { AuthSelectors } from '../auth/auth-selectors';
import { EventState } from './event-state';

const getState = (state: RootState): EventState => state.event;

const getEventList = createSelector(getState, (state) => state.eventList);
const hasMoreEvents = createSelector(getState, (state) => !!state.hasMoreEvents);

const getEventDetail = createSelector(getState, (state) => state.eventDetail);
const getEventPolls = createSelector(getState, (state) => state.eventPolls);
const getEventPollVoters = createSelector(getState, (state) => state.eventPollVoters);
const getEventParticipants = createSelector(getState, (state) => state.eventParticipants);
const getEventAccessRequest = createSelector(getState, (state) => state.eventAccessRequest);
const getRsvpStatus = createSelector(
  getEventDetail,
  getEventParticipants,
  getEventAccessRequest,
  AuthSelectors.getCurrentUserId,
  (event, participants, accessRequest, currentUserId): EventParticipantResponse => {
    if (!event || !currentUserId) {
      return 'unknown';
    } else if (currentUserId === event.host) {
      return 'collaborating';
    } else if (accessRequest) {
      return accessRequest.response;
    } else {
      const self = participants?.find((participant) => participant.uid === currentUserId);

      return self?.response || 'unknown';
    }
  }
);
const isHost = createSelector(getRsvpStatus, (response) => response === 'collaborating');
const isParticipant = createSelector(
  getEventParticipants,
  AuthSelectors.getCurrentUserId,
  (participants, currentUserId) =>
    !!(currentUserId && participants?.some((p) => currentUserId === p.uid))
);
const totalParticipants = createSelector(
  getEventParticipants,
  (participants) => participants?.filter((p) => p.response !== 'collaborating').length || 0
);
const getWhenPoll = createSelector(
  getEventPolls,
  (polls) =>
    polls?.find((poll) => poll.pollKind === 'when') as CollectionItemModel<EventTimePollModel>
);
const getWherePoll = createSelector(
  getEventPolls,
  (polls) =>
    polls?.find((poll) => poll.pollKind === 'where') as CollectionItemModel<EventLocationPollModel>
);
const getPollVoters = <T extends EventPollModel>(
  poll: CollectionItemModel<T>,
  voters: Record<string, ObservedCollectionModel<EventPollVoterModel>>
): EventPollVotesModel | undefined => {
  if (!poll) {
    return;
  }

  const result: EventPollVotesModel = {};

  Object.keys(poll.options).forEach((optionId) => {
    result[optionId] = [];
  });

  voters[poll._id]?.forEach((voter) => {
    voter.votes.forEach((voteOptionId) => {
      if (Array.isArray(result[voteOptionId])) {
        result[voteOptionId]?.push(voter._id);
      }
    });
  });

  return result;
};
const getWhenVoters = createSelector(getWhenPoll, getEventPollVoters, getPollVoters);
const getWhereVoters = createSelector(getWherePoll, getEventPollVoters, getPollVoters);
const getPollVotes = <T extends EventPollModel>(
  poll: CollectionItemModel<T>,
  voters: Record<string, ObservedCollectionModel<EventPollVoterModel>>,
  accessRequest?: EventAccessRequestModel,
  currentUserId?: string
) => {
  if (!currentUserId || !poll || (!voters[poll._id] && !accessRequest?.votes[poll._id])) {
    return [];
  }

  return accessRequest
    ? accessRequest.votes[poll._id] || []
    : voters[poll._id]?.find((voter) => voter._id === currentUserId)?.votes || [];
};
const getMyWhenVotes = createSelector(
  getWhenPoll,
  getEventPollVoters,
  getEventAccessRequest,
  AuthSelectors.getCurrentUserId,
  getPollVotes
);
const getMyWhereVotes = createSelector(
  getWherePoll,
  getEventPollVoters,
  getEventAccessRequest,
  AuthSelectors.getCurrentUserId,
  getPollVotes
);

const getEventUserIds = createSelector(
  getEventDetail,
  getEventParticipants,
  (event, participants) => {
    const userIds = [];

    if (event) {
      userIds.push(event.host);
      participants.forEach((participant) => userIds.push(participant.uid));
    }

    return userIds;
  }
);

const getEventListUserIds = createSelector(getEventList, (eventList) => {
  return eventList?.map((event) => event.host) || [];
});

interface EventDetailView {
  event: EventModel | null;
  isHost: boolean;
  myWhenVotes: string[];
  myWhereVotes: string[];
  participants: ObservedCollectionModel<EventParticipantModel> | null;
  rsvpStatus: EventParticipantResponse;
  totalParticipants: number;
  userIds: string[];
  whenPoll?: CollectionItemModel<EventTimePollModel>;
  whenVoters?: EventPollVotesModel;
  wherePoll?: CollectionItemModel<EventLocationPollModel>;
  whereVoters?: EventPollVotesModel;
}
const getEventDetailView = createStructuredSelector<RootState, EventDetailView>({
  event: getEventDetail,
  isHost: isHost,
  myWhenVotes: getMyWhenVotes,
  myWhereVotes: getMyWhereVotes,
  participants: getEventParticipants,
  rsvpStatus: getRsvpStatus,
  totalParticipants: totalParticipants,
  userIds: getEventUserIds,
  whenPoll: getWhenPoll,
  whenVoters: getWhenVoters,
  wherePoll: getWherePoll,
  whereVoters: getWhereVoters,
});

export const EventSelectors = {
  getEventAccessRequest,
  getEventDetail,
  getEventDetailView,
  getEventList,
  getEventListUserIds,
  getEventParticipants,
  getEventPolls,
  getEventUserIds,
  getMyWhenVotes,
  getMyWhereVotes,
  getRsvpStatus,
  getWhenPoll,
  getWhenVoters,
  getWherePoll,
  getWhereVoters,
  hasMoreEvents,
  isHost,
  isParticipant,
  totalParticipants,
} as const;
