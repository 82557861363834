import React, { FC } from 'react';
import { Provider } from 'react-redux';

import { useConstant } from '@stur/hooks/use-constant';
import { createStore } from '@stur/store/store';

/**
 * Gatsby root element wrapper for browser see {@link '/gatsby-browser.js'}
 *
 * Defines Providers so they are available to WrapPage components
 */
export const WrapRootBrowser: FC = ({ children }) => {
  const store = useConstant(() =>
    createStore({
      browser: {
        isBrowser: true,
      },
    })
  );

  return <Provider store={store}>{children}</Provider>;
};

/**
 * Gatsby root element wrapper for SSR see {@link '/gatsby-ssr.js'}
 *
 * Defines Providers so they are available to WrapPage components
 */
export const WrapRootSSR: FC = ({ children }) => {
  const store = useConstant(() =>
    createStore({
      browser: {
        isBrowser: false,
        breakpoint: 'large',
      },
      auth: {
        isInitialized: true,
      },
    })
  );

  return <Provider store={store}>{children}</Provider>;
};
