import type firebase from 'firebase';
import _isDate from 'lodash/isDate';
import _isNil from 'lodash/isNil';
import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';
import _mapValues from 'lodash/mapValues';

import { CollectionModel } from '@stur/models/collection-model';
import { ObservedCollectionModel } from '@stur/models/observed-model';
import { FirebaseLib, FirebaseSession } from '@stur/services/firebase-service';

type DocumentValue =
  | firebase.firestore.Timestamp
  | Array<DocumentValue>
  | Record<string, unknown>
  | unknown;

/**
 * From moment.js
 */
const extendedIsoRegex = /^\s*((?:[+-]\d{6}|\d{4})-(?:\d\d-\d\d|W\d\d-\d|W\d\d|\d\d\d|\d\d))(?:(T| )(\d\d(?::\d\d(?::\d\d(?:[,.]\d+)?)?)?)([+-]\d\d(?::?\d\d)?|\s*Z)?)?$/;
const bsicIsoRegex = /^\s*((?:[+-]\d{6}|\d{4})(?:\d\d\d\d|W\d\d\d|W\d\d|\d\d\d|\d\d)?)(?:(T| )(\d\d(?:\d\d(?:\d\d(?:[,.]\d+)?)?)?)([+-]\d\d(?::?\d\d)?|\s*Z)?)?$/;

const isIsoDate = (dateString: string): boolean => {
  return bsicIsoRegex.test(dateString) || extendedIsoRegex.test(dateString);
};

/**
 * Recursively convert any properties of type Timestamp to an ISO date string
 */
const serializeTimestamps = (obj: DocumentValue, lib: FirebaseLib): DocumentValue => {
  if (obj instanceof lib.firestore.Timestamp) {
    return obj.toDate().toISOString();
  } else if (Array.isArray(obj)) {
    return obj.map((item) => serializeTimestamps(item, lib));
  } else if (_isObject(obj)) {
    return _mapValues(obj, (val) => serializeTimestamps(val, lib));
  } else {
    return obj;
  }
};

/**
 * Recursively convert any properties formatted as ISO date strings to Timestamps
 */
const deserializeTimestamps = (obj: DocumentValue, lib: FirebaseLib): DocumentValue => {
  if (_isString(obj) && isIsoDate(obj)) {
    return toTimestamp(lib, obj);
  } else if (Array.isArray(obj)) {
    return obj.map((item) => deserializeTimestamps(item, lib));
  } else if (_isObject(obj)) {
    return _mapValues(obj, (val) => deserializeTimestamps(val, lib));
  } else {
    return obj;
  }
};

/**
 * Returns a converter for use with the firestore withConverter() method that
 * serializes firebase Timestamps to ISO date strings
 *
 * This is used to ensure models stored in redux are serializable.
 */
const getDateConverter = async <T>(
  lib: FirebaseLib
): Promise<firebase.firestore.FirestoreDataConverter<T>> => {
  return {
    toFirestore(post: T): firebase.firestore.DocumentData {
      return deserializeTimestamps(post, lib) as firebase.firestore.DocumentData;
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions
    ): T {
      const data = snapshot.data(options);
      return lib ? (serializeTimestamps(data, lib) as T) : (data as T);
    },
  };
};

/**
 * Convert a date to a firebase timestamp
 */
const toTimestamp = (
  lib: FirebaseLib,
  date?: string | number | Date | null
): firebase.firestore.Timestamp | null => {
  if (_isNil(date)) {
    return null;
  }
  if (_isDate(date)) {
    return lib.firestore.Timestamp.fromDate(date);
  }
  return lib.firestore.Timestamp.fromDate(new Date(date));
};

/**
 * Convienence method for basic document fetch
 */
const getDocument = <T>(
  session: FirebaseSession,
  path: string
): firebase.firestore.DocumentReference<T> => {
  const { app, dateConverter } = session;
  return app
    .firestore()
    .doc(path)
    .withConverter<T>(dateConverter as firebase.firestore.FirestoreDataConverter<T>);
};

/**
 * Convienence method for basic collection fetch
 */
const getCollection = <T>(
  session: FirebaseSession,
  path: string
): firebase.firestore.CollectionReference<T> => {
  const { app, dateConverter } = session;
  return app
    .firestore()
    .collection(path)
    .withConverter<T>(dateConverter as firebase.firestore.FirestoreDataConverter<T>);
};

/**
 * Map each element of a query snapshot to a given type
 */
const mapCollection = <T>(snapshot: firebase.firestore.QuerySnapshot): CollectionModel<T> => {
  return snapshot.docs.map((doc) => ({
    ...(doc.data() as T),
    _id: doc.id,
  }));
};

/**
 * Map each element of a query snapshot to an ObservedModel for that type
 */
const mapObservedCollection = <T>(
  snapshot: firebase.firestore.QuerySnapshot,
  isInitial?: boolean
): ObservedCollectionModel<T> => {
  return snapshot.docChanges().map((changes) => ({
    ...(changes.doc.data() as T),
    _id: changes.doc.id,
    _lastUpdate: isInitial ? undefined : Date.now(),
    _changeType: changes.type,
  }));
};

export const FirebaseUtils = {
  getCollection,
  getDateConverter,
  getDocument,
  mapCollection,
  mapObservedCollection,
  toTimestamp,
} as const;
