import {
  Action,
  AnyAction,
  AsyncThunk,
  configureStore,
  DeepPartial,
  getDefaultMiddleware,
  ThunkAction,
  ThunkDispatch,
} from '@reduxjs/toolkit';

import { crashReporterMiddleware } from './middleware/crash-reporter-middleware';
import rootReducer, { RootState } from './root-reducer';

const isProduction = process.env.NODE_ENV === 'production';

/**
 * Bootstrap the Redux store
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createStore = (preloadedState?: DeepPartial<RootState>) => {
  const store = configureStore({
    reducer: rootReducer,
    devTools: !isProduction,
    preloadedState,
    middleware: getDefaultMiddleware().concat(crashReporterMiddleware),
  });

  // support hot reload
  if (!isProduction && module.hot) {
    module.hot.accept('./root-reducer', () => {
      import('./root-reducer')
        .then((m) => m.default)
        .then((newRootReducer) => {
          return store.replaceReducer(newRootReducer);
        })
        .catch((error) => {
          console.error('Root reducer hot update failed');
        });
    });
  }

  return store;
};

// ============================================================================
// Reference types
//

export type AppStore = ReturnType<typeof createStore>;
let store: AppStore;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export interface AsyncThunkConfig<
  RejectValue = null,
  ExtraArgument = unknown,
  SerializedErrorType = unknown
> {
  dispatch: ThunkDispatch<RootState, ExtraArgument, AnyAction>;
  state: RootState;
  requestId: string;
  signal?: AbortSignal;
  extra: unknown;
  rejectValue: RejectValue;
  serializedErrorType?: SerializedErrorType;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GenericAsyncThunk = AsyncThunk<any, any, AsyncThunkConfig<any>>;
