/**
 * Specify import order for imports used across chunks to resolve the
 * "webpack conflicting order" warning during build
 */
import '@stur/components/layout/page-footer/page-footer.module.scss';
import '@stur/components/layout/page-header/page-header.module.scss';
import '@stur/components/layout/page-title/page-title.module.scss';

import _constant from 'lodash/constant';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotificationsSystem from 'reapop';

import { ToastNotification, ToastTransition } from '@stur/components/core/toast-notification';
import { useEffectOnce } from '@stur/hooks/use-effect-once';
import { useMediaQueryListeners } from '@stur/hooks/use-media-query-listeners';
import { breakpointValues } from '@stur/models/breakpoint-model';
import { FirebaseService } from '@stur/services/firebase-service';
import { AuthActions } from '@stur/store/auth/auth-reducer';
import { BrowserActions } from '@stur/store/browser/browser-reducer';
import { NotificationActions } from '@stur/store/notification/notification-reducer';
import { NotificationSelectors } from '@stur/store/notification/notification-selectors';

/**
 * Gatsby page element wrapper for browser see {@link '/gatsby-browser.js'}
 *
 * Bootstraps the global components of the application
 * Consumes Providers from WrapRoot components
 */
export const WrapPageBrowser: FC = ({ children }) => {
  const dispatch = useDispatch();

  // connect NotificationSystem to store
  const notifications = useSelector(NotificationSelectors.getNotifications);
  const handleDismissNotification = (id: string) => dispatch(NotificationActions.dismiss(id));

  useEffectOnce(() => {
    // connect firebase auth state change to the store
    FirebaseService.authStateChanged.subscribe((user) => {
      if (user?.uid) {
        dispatch(AuthActions.completeLogin({ uid: user.uid }));
      } else {
        dispatch(AuthActions.completeLogout());
      }
    });

    // load firebase & check auth
    FirebaseService.init();
  });

  // connect media query listeners to the store
  const initialized = useMediaQueryListeners((breakpoint) =>
    dispatch(BrowserActions.setBreakpoint({ breakpoint }))
  );

  // don't render until initial breakpoint is set
  if (!initialized) {
    return null;
  }

  return (
    <>
      {children}

      <NotificationsSystem
        notifications={notifications || []}
        smallScreenBreakpoint={breakpointValues.medium}
        dismissNotification={handleDismissNotification}
        components={{
          NotificationIcon: _constant(null),
          Notification: ToastNotification,
          Transition: ToastTransition,
        }}
      />
    </>
  );
};

/**
 * Gatsby page element wrapper for SSR see {@link '/gatsby-ssr.js'}
 *
 * Bootstraps the global components of the application
 * Consumes Providers from WrapRoot components
 */
export const WrapPageSSR: FC = ({ children }) => {
  return <>{children}</>;
};
