import { MappedError, MappedErrorData, MappedErrorMessages } from './mapped-error';

export type EventServiceErrorCode =
  | 'data/event-not-exists'
  | 'data/invite-not-exists'
  | 'data/request-already-exists';

export interface EventServiceErrorData extends MappedErrorData {
  eventId?: string;
  userId?: string;
}

const messages: MappedErrorMessages<EventServiceErrorCode> = {
  'data/event-not-exists': 'Event ${eventId} not found',
  'data/invite-not-exists': 'User ${userId} has no invite for event ${eventId}',
  'data/request-already-exists': 'User ${userID} has already requested access to event ${eventId}',
};

export class EventServiceError extends MappedError<EventServiceErrorCode, EventServiceErrorData> {
  constructor(code: EventServiceErrorCode, data?: EventServiceErrorData, ...causes: Error[]) {
    super(code, data, messages, ...causes);
  }
}
