// input detection (browser only)
import 'what-input';

// global styles
import './src/styles/index.scss';

import { createElement } from 'react';

import { WrapPageBrowser } from '@stur/containers/wrap-page';
import { WrapRootBrowser } from '@stur/containers/wrap-root';

// root element
export const wrapRootElement = ({ element }) => {
  return createElement(WrapRootBrowser, null, element);
};

// page element
export const wrapPageElement = ({ element }) => {
  return createElement(WrapPageBrowser, null, element);
};
