import type firebase from 'firebase';
import _isFunction from 'lodash/isFunction';
import _noop from 'lodash/noop';
import { from, fromEventPattern, NEVER, Observable, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { ObservedCollectionModel } from '@stur/models/observed-model';
import { DOMUtils } from '@stur/utils/dom-utils';
import { FirebaseUtils } from '@stur/utils/firebase-utils';

export type FirebaseLib = typeof firebase;

export interface FirebaseSession {
  lib: FirebaseLib;
  app: firebase.app.App;
  facebookAuthProvider: firebase.auth.FacebookAuthProvider;
  googleAuthProvider: firebase.auth.GoogleAuthProvider;
  dateConverter: firebase.firestore.FirestoreDataConverter<unknown>;
}

// TODO: move this to an environment config file
const config = {
  apiKey: 'AIzaSyD-LZN_2BZXB0xWrztUvaKQdE5FqpgwvnM',
  authDomain: 'stur-dev-29d2c.firebaseapp.com',
  databaseURL: 'https://stur-dev-29d2c.firebaseio.com',
  projectId: 'stur-dev-29d2c',
  storageBucket: 'stur-dev-29d2c.appspot.com',
  messagingSenderId: '927070902086',
  appId: '1:927070902086:web:9752d22f24e6a1defc3747',
  measurementId: 'G-QDKNDJSW5P',
};

let _firebase: Promise<FirebaseLib> | undefined;
let _session: Promise<FirebaseSession> | undefined;
let listenerCount = 0;
const authStateChanged$ = new Subject<firebase.User | null>();

function updateListenerCount(operation: 'add' | 'remove', ...extra: string[]) {
  if (operation === 'add') {
    listenerCount++;
  } else {
    listenerCount--;
  }

  console.log('Firestore listener count:', listenerCount, ...extra);
}

/**
 * Load firebase modules
 * Using async import() allows webpack to load them in separate chunks
 */
async function loadFirebase(): Promise<FirebaseLib> {
  const module = await import('firebase/app');
  await Promise.all([import('firebase/auth'), import('firebase/firestore')]);
  return module.default;
}

async function withSession<T>(fn: (session: FirebaseSession) => T | Promise<T>): Promise<T | null> {
  if (DOMUtils.isSSR()) {
    return null;
  }

  if (!_firebase) {
    _firebase = loadFirebase();
  }

  if (!_session) {
    _session = beginSession(_firebase);
  }

  const session = await _session;
  return await fn(session);
}

function getSession(): Observable<FirebaseSession> {
  if (DOMUtils.isSSR()) {
    return NEVER;
  }

  if (!_firebase) {
    _firebase = loadFirebase();
  }

  if (!_session) {
    _session = beginSession(_firebase);
  }

  return from(_session);
}

async function beginSession(_load: Promise<FirebaseLib>): Promise<FirebaseSession> {
  const lib = await _load;
  const app = lib.initializeApp(config) as firebase.app.App;
  const facebookAuthProvider = new lib.auth.FacebookAuthProvider();
  const googleAuthProvider = new lib.auth.GoogleAuthProvider();

  try {
    await app.firestore().enablePersistence({ synchronizeTabs: true });
    await app.auth().setPersistence(lib.auth.Auth.Persistence.LOCAL);

    app.auth().onAuthStateChanged((user) => authStateChanged$.next(user));
  } catch (error) {
    // TODO: log this
    console.warn('Could not enable persistence', error);
  }

  const dateConverter = await FirebaseUtils.getDateConverter(lib);

  return {
    lib,
    app,
    facebookAuthProvider,
    googleAuthProvider,
    dateConverter,
  };
}

async function init(): Promise<void> {
  withSession(_noop);
}

async function endSession(): Promise<void> {
  if (!_session) {
    return;
  }

  const { app } = await _session;
  try {
    await app.firestore().terminate();
    await app.firestore().clearPersistence();
  } catch (error) {
    // TODO: log this
    console.warn('Failed to terminate firestore', error);
  }

  try {
    await app.delete();
  } catch (error) {
    // TODO: log this
    console.warn('Failed to delete firebase app', error);
  }

  _session = undefined;
}

function observeCollection<T>(path: string): Observable<ObservedCollectionModel<T>> {
  let unsubscribe: undefined | (() => void);
  let initial = true;

  return getSession().pipe(
    switchMap((session) => {
      return fromEventPattern<firebase.firestore.QuerySnapshot<T>>(
        (handler) => {
          updateListenerCount('add', 'collection', path);
          const collection = FirebaseUtils.getCollection<T>(session, path);
          unsubscribe = collection.onSnapshot(handler);
        },
        () => {
          updateListenerCount('remove', 'collection', path);
          if (_isFunction(unsubscribe)) {
            unsubscribe();
          }
        }
      );
    }),
    map((snapshot) => FirebaseUtils.mapObservedCollection<T>(snapshot, initial)),
    tap(() => (initial = false))
  );
}

function observeDocument<T>(path: string): Observable<T> {
  let unsubscribe: undefined | (() => void);

  return getSession().pipe(
    switchMap((session) => {
      return fromEventPattern<firebase.firestore.DocumentSnapshot<T>>(
        (handler) => {
          updateListenerCount('add', 'document', path);
          const document = FirebaseUtils.getDocument<T>(session, path);
          unsubscribe = document.onSnapshot(handler);
        },
        () => {
          updateListenerCount('remove', 'document', path);
          if (_isFunction(unsubscribe)) {
            unsubscribe();
          }
        }
      );
    }),
    map((snapshot) => snapshot.data() as T)
  );
}

function observeDocumentExists<T>(path: string): Observable<boolean> {
  let unsubscribe: undefined | (() => void);

  return getSession().pipe(
    switchMap((session) => {
      return fromEventPattern<firebase.firestore.DocumentSnapshot<T>>(
        (handler) => {
          updateListenerCount('add', 'document exists', path);
          const document = FirebaseUtils.getDocument<T>(session, path);
          unsubscribe = document.onSnapshot(handler);
        },
        () => {
          updateListenerCount('remove', 'document exists', path);
          if (_isFunction(unsubscribe)) {
            unsubscribe();
          }
        }
      );
    }),
    map((snapshot) => snapshot.exists)
  );
}

export const FirebaseService = {
  authStateChanged: authStateChanged$.asObservable(),
  endSession,
  init,
  observeCollection,
  observeDocument,
  observeDocumentExists,
  withSession,
} as const;
