import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import type { Draft, WritableDraft } from 'immer/dist/types/types-external';
import { fromEventPattern, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ObservedCollectionModel } from '@stur/models/observed-model';
import { AsyncThunkConfig } from '@stur/store/store';

/**
 * Use with redux toolkit's createSlice method to define an action/action
 * creator/reducer combo which sets a single state value.
 */
function makeSetter<S, F extends keyof S>(
  field: F
): (state: WritableDraft<S>, action: PayloadAction<{ [P in F]: S[P] }>) => void {
  return (state, action) => {
    state[field] = (action.payload[field] as unknown) as Draft<S[F]>;
  };
}

/**
 * Observer thunks work similar to async thunks, except the `fulfilled` action
 * can be dispatched multiple times and it will remain active indefinitely
 * until aborted
 */
function createObserverThunk<Payload, ThunkArg>(
  typePrefix: string,
  service: (request: ThunkArg) => Observable<Payload>
) {
  const asyncThunk = createAsyncThunk<Payload, ThunkArg, AsyncThunkConfig>(
    typePrefix,
    async (request, thunkApi) => {
      return new Promise((resolve, reject) => {
        const aborted$ = fromEventPattern(
          (handler) => thunkApi.signal.addEventListener('abort', handler),
          (handler) => thunkApi.signal.removeEventListener('abort', handler)
        );

        let isFirst = true;
        service(request)
          .pipe(takeUntil(aborted$))
          .subscribe(
            (value) => {
              if (isFirst) {
                // send the initial fulfilled promise using the first value emitted from the observable
                resolve(value);
                isFirst = false;
              } else {
                // dispatch "extra" fulfilled actions on values emitted after the first
                thunkApi.dispatch(asyncThunk.fulfilled(value, thunkApi.requestId, request));
              }
            },
            (error) => {
              if (isFirst) {
                reject(error);
                isFirst = false;
              } else {
                thunkApi.dispatch(asyncThunk.rejected(error, thunkApi.requestId, request));
              }
            }
          );
      });
    }
  );

  return asyncThunk;
}

/**
 * Modifies the local version of a collection to sync it with the database using query
 * snapshot data from a Firebase collection listener.
 *
 * @param newValue The action payload value
 * @param currentValue The current state value
 * @returns An updated (mutated) or new state value
 */
function mergeObservedState<T>(
  newValue: ObservedCollectionModel<T>,
  currentValue?: ObservedCollectionModel<T>
): ObservedCollectionModel<T> {
  if (currentValue) {
    newValue.forEach((change) => {
      const index = currentValue.findIndex((p) => p._id === change._id);

      switch (change._changeType) {
        case 'removed':
          if (index >= 0) {
            currentValue.splice(index, 1);
          }
          break;
        default:
        case 'modified':
          if (index >= 0) {
            currentValue.splice(index, 1, change);
          } else {
            currentValue.push(change);
          }
          break;
      }
    });

    return currentValue;
  } else {
    return newValue.map((change) => {
      const { _changeType, _lastUpdate, ...rest } = change;
      return rest;
    }) as ObservedCollectionModel<T>;
  }
}

export const ReduxUtils = {
  createObserverThunk,
  makeSetter,
  mergeObservedState,
} as const;
