import classNames from 'classnames';
import { Link } from 'gatsby';
import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  FC,
  MouseEventHandler,
  ReactNode,
} from 'react';

export type ButtonSize = 'small' | 'default';

export type ButtonColor = 'primary' | 'secondary' | 'primary-light' | 'secondary-light';

export type ButtonVariant = 'solid' | 'clear' | 'hollow' | 'link' | 'none';

export interface ButtonBaseProps {
  size?: ButtonSize;
  color?: ButtonColor;
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
  variant?: ButtonVariant;
  expanded?: boolean;
}

export type ButtonProps = ButtonBaseProps &
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export interface LinkButtonProps extends ButtonBaseProps {
  to: string;
  replace?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

function getButtonClassNames(props: ButtonBaseProps, isButton: boolean): string {
  const variant = props.variant === 'none' ? undefined : props.variant || 'solid';
  return classNames('button', props.className, variant, props.size, props.color, {
    disabled: props.disabled && !isButton,
    expanded: !!props.expanded,
  });
}

export const Button: FC<ButtonProps> = (props) => {
  const { expanded, size, color, children, className, variant, type = 'button', ...others } = props;
  return (
    <button type={type} className={getButtonClassNames(props, true)} {...others}>
      {children}
    </button>
  );
};

export const LinkButton: FC<LinkButtonProps> = (props) => {
  const { to, children, disabled, replace, onClick } = props;
  const isInternal = /^\/(?!\/)/.test(to);

  if (isInternal) {
    return (
      <Link
        className={getButtonClassNames(props, false)}
        to={to}
        replace={replace}
        onClick={onClick}
      >
        {children}
      </Link>
    );
  }

  return (
    <a
      className={getButtonClassNames(props, false)}
      href={to}
      target="blank"
      aria-disabled={disabled}
      onClick={onClick}
    >
      {children}
    </a>
  );
};
