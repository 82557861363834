import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FriendUserModel } from '@stur/models/friend-user-model';
import { UserService } from '@stur/services/user-service';

import { AuthActions } from '../auth/auth-reducer';
import { AuthSelectors } from '../auth/auth-selectors';
import { AsyncThunkConfig } from '../store';
import { UserState } from './user-state';

const initialState: UserState = {
  userCache: {},
};

export interface CacheUsersPayload {
  uids: string[];
}
const cacheUsers = createAsyncThunk<
  Record<string, FriendUserModel>,
  CacheUsersPayload,
  AsyncThunkConfig
>('user/cacheUsers', async (request, thunkApi) => {
  const { uids } = request;
  const state = thunkApi.getState();
  const currentUser = AuthSelectors.getCurrentUser(state);
  const containsSelf = currentUser && uids.includes(currentUser.uid);
  const filteredUids = containsSelf
    ? uids.filter((uid) => !currentUser || uid !== currentUser.uid)
    : uids;
  const promises = filteredUids.map((uid) => UserService.getFriendUser(uid, currentUser?.uid));
  const users = await Promise.all(promises);
  const map: Record<string, FriendUserModel> = {};

  // pull current user data from auth store (saves unnecessary request)
  if (currentUser && containsSelf) {
    map[currentUser.uid] = { ...currentUser, favorite: false };
  }

  filteredUids.forEach((uid, index) => {
    const user = users[index];
    if (user) {
      map[uid] = user;
    }
  });

  return map;
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUserCache: (state) => {
      state.userCache = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AuthActions.completeLogout.fulfilled, (state) => {
      Object.assign(state, {
        ...initialState,
      });
    });

    builder.addCase(cacheUsers.fulfilled, (state, { payload }) => {
      state.userCache = payload;
    });
  },
});

export const UserActions = {
  ...userSlice.actions,
  cacheUsers,
};

export default userSlice.reducer;
