import classNames from 'classnames';
import IcomoonReact from 'icomoon-react';
import React, { VFC } from 'react';

import { IconName } from './icon-name';
import iconSet from './icon-set.json';

export interface IconProps {
  color?: string;
  size?: string | number;
  name: IconName;
  className?: string;
}

export const Icon: VFC<IconProps> = ({ color, size = '100%', name, className = '' }) => {
  return (
    <IcomoonReact
      className={classNames('icon', className, { auto: size === '100%' })}
      iconSet={iconSet}
      color={color}
      size={size}
      icon={name}
    />
  );
};
