import type firebase from 'firebase';
import _set from 'lodash/set';
import { Observable } from 'rxjs';

import { EventServiceError } from '@stur/errors/event-service-error';
import { CollectionModel } from '@stur/models/collection-model';
import { EventAccessRequestModel } from '@stur/models/event-access-request-model';
import { EventModel, EventPollModel, UserEventModel } from '@stur/models/event-model';
import {
  EventParticipantModel,
  EventParticipantResponse,
} from '@stur/models/event-participant-model';
import { EventPollVoterModel } from '@stur/models/event-poll-voter-model';
import { ObservedCollectionModel } from '@stur/models/observed-model';
import { UserPollVoteModel } from '@stur/models/user-poll-vote-model';
import { FirebaseUtils } from '@stur/utils/firebase-utils';

import { CacheService } from './cache-service';
import { FirebaseService } from './firebase-service';

export interface ListEventsRequest {
  uid: string;
  startAfter?: string;
}
export interface ListEventsResponse {
  hasMore: boolean;
  events: EventModel[];
}
async function listEvents(request: ListEventsRequest): Promise<ListEventsResponse | null> {
  return FirebaseService.withSession(async (session) => {
    const { app, lib, dateConverter } = session;
    const { uid, startAfter } = request;
    const pageSize = 6;
    const startAfterCursor = startAfter
      ? await FirebaseUtils.getDocument<UserEventModel>(
          session,
          `users/${uid}/my-events/${startAfter}`
        ).get()
      : null;
    const query = app
      .firestore()
      .collection('users')
      .doc(uid)
      .collection('my-events')
      .where('eventKind', '!=', 'plan')
      .orderBy('eventKind', 'asc')
      .orderBy('orderDate', 'asc')
      .startAfter(startAfterCursor)
      .limit(pageSize + 1)
      .withConverter(dateConverter as firebase.firestore.FirestoreDataConverter<UserEventModel>);

    const cacheKey = CacheService.cacheKey(`users/${uid}/my-events`, {
      orderBy: 'orderDate',
      startAfter,
      pageSize,
    });
    const snapshot = await CacheService.get(lib, cacheKey, query);
    const userEvents = snapshot.docs.map((doc) => doc.data() as UserEventModel);

    const events = await Promise.all(
      userEvents
        .slice(0, pageSize)
        .map((userEvent) =>
          CacheService.getDocument<EventModel>(session, `/events/${userEvent.eventId}`)
        )
    );

    return {
      events: events.filter((event) => !!event) as EventModel[],
      hasMore: userEvents.length > pageSize,
    };
  });
}

export interface GetEventRequest {
  eventId: string;
}
async function getEvent(request: GetEventRequest): Promise<EventModel | null> {
  return FirebaseService.withSession(async (session) => {
    const { eventId } = request;

    const event = await CacheService.getDocument<EventModel>(session, `/events/${eventId}`);
    return event || null;
  });
}

export interface GetEventPollsRequest {
  eventId: string;
}
async function getEventPolls(
  request: GetEventPollsRequest
): Promise<CollectionModel<EventPollModel> | null> {
  return FirebaseService.withSession(async (session) => {
    const { eventId } = request;

    const eventPolls = await CacheService.getCollection<EventPollModel>(
      session,
      `/events/${eventId}/event-polls`
    );
    return eventPolls || null;
  });
}

export interface GetAccessRequestRequest {
  userId?: string;
  eventId: string;
}
function getAccessRequest(
  request: GetAccessRequestRequest
): Promise<EventAccessRequestModel | null> {
  return FirebaseService.withSession(async (session) => {
    const { eventId, userId } = request;

    const document = FirebaseUtils.getDocument<EventAccessRequestModel>(
      session,
      `events/${eventId}/requests/${userId}`
    );
    const snapshot = await document.get();
    return snapshot.data() || null;
  });
}

export interface ObserveEventPollVotersRequest {
  eventId: string;
  pollId: string;
}
function observeEventPollVoters(
  request: ObserveEventPollVotersRequest
): Observable<ObservedCollectionModel<EventPollVoterModel>> {
  const { eventId, pollId } = request;
  return FirebaseService.observeCollection(
    `/events/${eventId}/event-polls/${pollId}/event-poll-option-voters`
  );
}

export interface SetPollVotesRequest {
  eventId: string;
  pollId: string;
  userId: string;
  votes: string[];
}
function setPollVotes(request: SetPollVotesRequest): Promise<void | null> {
  return FirebaseService.withSession(async (session) => {
    const { eventId, pollId, userId, votes } = request;

    return session.app
      .firestore()
      .doc(`/events/${eventId}/event-polls/${pollId}/event-poll-option-voters/${userId}`)
      .set({
        votes,
      });
  });
}
function requestPollVotes(request: SetPollVotesRequest): Promise<EventAccessRequestModel | null> {
  return FirebaseService.withSession(async (session) => {
    const { eventId, pollId, userId, votes } = request;
    const { app, dateConverter } = session;
    let accessRequest: EventAccessRequestModel | null = null;

    const requestDoc = app
      .firestore()
      .doc(`/events/${eventId}/requests/${userId}`)
      .withConverter(
        dateConverter as firebase.firestore.FirestoreDataConverter<EventAccessRequestModel>
      );
    const requestSnapshot = await requestDoc.get();

    if (requestSnapshot.exists) {
      accessRequest = requestSnapshot.data() as EventAccessRequestModel;

      _set(accessRequest, `votes.${pollId}`, votes);
      await requestDoc.set({ votes: accessRequest.votes }, { merge: true });
    } else {
      accessRequest = {
        created: new Date().toISOString(),
        votes: {
          [pollId]: votes,
        },
        response: 'unknown',
        status: 'pending',
      };

      await requestDoc.set(accessRequest);
    }

    return accessRequest;
  });
}

export interface ObserveEventParticipantsRequest {
  eventId: string;
}
function observeEventParticipants(
  request: ObserveEventParticipantsRequest
): Observable<ObservedCollectionModel<EventParticipantModel>> {
  const { eventId } = request;
  return FirebaseService.observeCollection(`/events/${eventId}/event-participants`);
}

export interface SetResponseRequest {
  userId: string;
  eventId: string;
  response: EventParticipantResponse;
}
async function setResponse(request: SetResponseRequest): Promise<void | null> {
  const { eventId, response, userId } = request;

  return FirebaseService.withSession(async (session) => {
    const { app } = session;

    const eventRef = FirebaseUtils.getDocument<EventModel>(session, `events/${eventId}`);
    const inviteResponseRef = FirebaseUtils.getDocument<EventParticipantModel>(
      session,
      `events/${eventId}/event-participants/${userId}`
    );
    const userEventPollVoteRef = FirebaseUtils.getDocument<UserPollVoteModel>(
      session,
      `users/${userId}/event-poll-votes/${eventId}`
    );
    const unansweredRef = FirebaseUtils.getDocument<UserEventModel>(
      session,
      `users/${userId}/my-events-unanswered/${eventId}`
    );
    const eventGroupListRef = FirebaseUtils.getDocument<UserEventModel>(
      session,
      `users/${userId}/my-events/${eventId}`
    );

    const snapshotEvent = await eventRef.get();
    if (!snapshotEvent.exists) {
      throw new EventServiceError('data/event-not-exists', { eventId });
    }
    const snapshotInvite = await inviteResponseRef.get();
    if (!snapshotInvite.exists) {
      throw new EventServiceError('data/invite-not-exists', { eventId, userId });
    }
    const snapshotMyEvent = await eventGroupListRef.get();
    const myEventData = snapshotMyEvent.data();

    await app.firestore().runTransaction(async (transaction) => {
      // mark the response on their invite
      transaction.update(inviteResponseRef, { response });

      // figure out what to do about unanswered events list based on the response
      if (response === 'unknown' && snapshotMyEvent.exists) {
        transaction.set(
          unansweredRef,
          {
            ...myEventData,
            group: 'new',
          },
          { merge: true }
        );
      } else {
        transaction.delete(unansweredRef);
      }

      // update the my-events list based on the response
      const group = response === 'unknown' ? 'new' : response;

      transaction.update(eventGroupListRef, { group });

      // update stats on events, my-events, and resetting votes are handled via a function
      if (response === 'no' && snapshotEvent.data()?.pollOpen) {
        // user has responded no and event poll is still open, need to remove their votes on any polls for this event
        transaction.delete(userEventPollVoteRef);
      }
    });

    await CacheService.clear(`/events/${eventId}/event-participants`);
  });
}
async function requestResponse(
  request: SetResponseRequest
): Promise<EventAccessRequestModel | null> {
  const { eventId, response, userId } = request;

  return FirebaseService.withSession(async (session) => {
    const { app, dateConverter } = session;
    let accessRequest: EventAccessRequestModel | null = null;

    const requestDoc = app
      .firestore()
      .doc(`/events/${eventId}/requests/${userId}`)
      .withConverter(
        dateConverter as firebase.firestore.FirestoreDataConverter<EventAccessRequestModel>
      );
    const requestSnapshot = await requestDoc.get();

    if (requestSnapshot.exists) {
      accessRequest = requestSnapshot.data() as EventAccessRequestModel;
      accessRequest.response = response;
      await requestDoc.set({ response }, { merge: true });
    } else {
      accessRequest = {
        created: new Date().toISOString(),
        votes: {},
        response,
        status: 'pending',
      };

      await requestDoc.set(accessRequest);
    }

    return accessRequest;
  });
}

export const EventService = {
  getAccessRequest,
  getEvent,
  getEventPolls,
  listEvents,
  observeEventParticipants,
  observeEventPollVoters,
  requestPollVotes,
  requestResponse,
  setPollVotes,
  setResponse,
} as const;
