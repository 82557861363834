import classNames from 'classnames';
import React, { ReactElement } from 'react';
import type Notification from 'reapop/dist/components/Notification';

import { Button } from '@stur/components/core/button';
import { Icon } from '@stur/components/core/icon';
import { StyleUtils } from '@stur/utils/style-utils';

import styles from './toast-notification.module.scss';

export type ToastNotificationProps = Parameters<typeof Notification>[0];

export const ToastNotification = (props: ToastNotificationProps): ReactElement => {
  const { notification, dismissNotification: dismiss } = props;
  const { id, title, message, status, dismissible, allowHTML } = notification;
  const dismissNotification = () => dismiss(id);

  function renderTitle() {
    if (allowHTML && title) {
      return <h4 className={styles.title} dangerouslySetInnerHTML={{ __html: title }}></h4>;
    }

    return <h4 className={styles.title}>{title}</h4>;
  }

  function renderMessage() {
    if (allowHTML && message) {
      return <div className={styles.message} dangerouslySetInnerHTML={{ __html: message }}></div>;
    }

    return <div className={styles.message}>{message}</div>;
  }

  function renderDismissButton() {
    if (!dismissible) {
      return null;
    }

    return (
      <Button variant="clear" className={styles.dismissButton} onClick={dismissNotification}>
        <Icon name="close-circle" />
      </Button>
    );
  }

  return (
    <div
      className={classNames(
        styles.container,
        StyleUtils.modifierClass(styles, 'container', status)
      )}
      data-testid="notification"
      role="alert"
    >
      <div className={styles.toast}>
        <div className={styles.titleBar}>
          {renderTitle()}
          {renderDismissButton()}
        </div>
        <div className={styles.body}>{renderMessage()}</div>
      </div>
    </div>
  );
};
