import { createSelector } from '@reduxjs/toolkit';

import { ServerValidationErrorMapping } from '@stur/errors/server-validation-error';
import { AuthUserModel } from '@stur/models/auth-user-model';
import { LinkAccountModel } from '@stur/models/link-account-model';
import { UserModel } from '@stur/models/user-model';
import { CompleteAccountRequest } from '@stur/services/auth-service';
import { RootState } from '@stur/store/root-reducer';

import { AuthState } from './auth-state';

const getState = (state: RootState): AuthState => state.auth;

const isInitialized = createSelector(getState, (auth) => auth.isInitialized);
const isLoggedIn = createSelector(getState, (auth): boolean => auth.isLoggedIn);
const getAuthUser = createSelector(getState, (auth): AuthUserModel | null => auth.authUser);
const getCurrentUser = createSelector(getState, (auth): UserModel | null => auth.currentUser);
const getCurrentUserId = createSelector(getCurrentUser, (user) => user?.uid);
const getLinkAccount = createSelector(
  getState,
  (auth): LinkAccountModel | null => auth.linkAccount
);
const getCompleteAccountErrors = createSelector(
  getState,
  (auth): Array<ServerValidationErrorMapping<CompleteAccountRequest>> | undefined =>
    auth.errors.completeAccount
);

export const AuthSelectors = {
  getAuthUser,
  getCompleteAccountErrors,
  getCurrentUser,
  getCurrentUserId,
  getLinkAccount,
  isInitialized,
  isLoggedIn,
} as const;
