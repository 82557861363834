import _template from 'lodash/template';
import { Primitive } from 'type-fest';
import ChainedError from 'typescript-chained-error';

export type MappedErrorMessages<T extends string> = Record<T, string>;

export type MappedErrorData = Record<string, Primitive>;

export class MappedError<
  ErrorCode extends string,
  ErrorData extends MappedErrorData
> extends ChainedError {
  constructor(
    public readonly code: ErrorCode,
    public readonly data: ErrorData | undefined,
    messages: Record<ErrorCode, string>,
    ...causes: Error[]
  ) {
    super(_template(messages[code])(data), ...causes);
  }
}
