import { FeatherCache, IFeatherCacheDriver } from 'feather-cache';
import type firebase from 'firebase';
import * as idb from 'idb-keyval';
import { Opaque } from 'type-fest';

import { CollectionModel } from '@stur/models/collection-model';
import { DOMUtils } from '@stur/utils/dom-utils';
import { FirebaseUtils } from '@stur/utils/firebase-utils';

import { FirebaseLib, FirebaseSession } from './firebase-service';

export type CacheKey = Opaque<string, 'CacheKey'>;

function createCustomStore(): idb.UseStore | undefined {
  if (DOMUtils.isSSR()) {
    return;
  }

  return idb.createStore('documentCache', 'expiration');
}

function createDriver(store: idb.UseStore): IFeatherCacheDriver {
  return {
    setFn: async (key: string, val: unknown) => await idb.set(key, val, store),
    getFn: async (key: string) => await idb.get(key, store),
    delFn: async (key: string) => await idb.del(key, store),
  };
}

const CACHE_ENABLED = true;
const DEFAULT_MAX_AGE = 60 * 5;
const customStore = createCustomStore();
const driver: IFeatherCacheDriver = customStore ? createDriver(customStore) : {};
const storage = new FeatherCache({
  maxAgeInMs: DEFAULT_MAX_AGE * 1000,
  ...driver,
});
const inFlight = new Map<CacheKey, Promise<CachableResponse<unknown>>>();

type CachableRequest<T> =
  | firebase.firestore.Query<T>
  | firebase.firestore.DocumentReference<T>
  | firebase.firestore.CollectionReference<T>;

type CachableResponse<T> =
  | firebase.firestore.QuerySnapshot<T>
  | firebase.firestore.DocumentSnapshot<T>;

async function get<T>(
  lib: FirebaseLib,
  cacheKey: CacheKey,
  request: firebase.firestore.Query<T>,
  maxAge?: number
): Promise<firebase.firestore.QuerySnapshot<T>>;

async function get<T>(
  lib: FirebaseLib,
  cacheKey: CacheKey,
  request: firebase.firestore.DocumentReference<T>,
  maxAge?: number
): Promise<firebase.firestore.DocumentSnapshot<T>>;

async function get<T>(
  lib: FirebaseLib,
  cacheKey: CacheKey,
  request: CachableRequest<T>,
  maxAge?: number
): Promise<firebase.firestore.QuerySnapshot<T>>;

async function get<T>(
  lib: FirebaseLib,
  cacheKey: CacheKey,
  request: firebase.firestore.CollectionReference<T>,
  maxAge?: number
): Promise<CachableResponse<T>>;

/**
 * Request a document or perform a query by first checking for a result from the cache
 * If the same request is made multiple times in a row, a shared promise will be returned
 * to avoid unnecessary calls.
 *
 * @param cacheKey uniquely identify the cache item including any parameters like sort
 * @param request a firebase Query or DocumentReference
 * @param maxAge max age in seconds
 */
async function get<T>(
  lib: FirebaseLib,
  cacheKey: CacheKey,
  request: CachableRequest<T>,
  maxAge = DEFAULT_MAX_AGE
): Promise<CachableResponse<T>> {
  if (inFlight.has(cacheKey)) {
    console.debug(`CacheService: cache hit (in flight) - ${cacheKey}`);
    const promise = inFlight.get(cacheKey) as Promise<CachableResponse<T>>;
    return promise;
  } else {
    const promise = _get(lib, cacheKey, request, maxAge);
    inFlight.set(cacheKey, promise);
    const response = await promise;
    inFlight.delete(cacheKey);
    return response;
  }
}

/**
 * Internal function to fetch data from the cache or server
 */
async function _get<T>(
  lib: FirebaseLib,
  cacheKey: CacheKey,
  request: CachableRequest<T>,
  maxAge = DEFAULT_MAX_AGE
): Promise<CachableResponse<T>> {
  let response: CachableResponse<T> | undefined;
  let cacheHit = false;

  if (!CACHE_ENABLED) {
    console.debug(`CacheService: cache miss (cache disabled) - ${cacheKey}`);
    response = await request.get();
  } else if (maxAge <= 0) {
    console.debug(`CacheService: cache miss (maxAge 0) - ${cacheKey}`);
    response = await request.get();
  } else if (await storage.get(cacheKey)) {
    try {
      response = await request.get({ source: 'cache' });
    } catch (error) {
      if (error.name === 'FirebaseError' && error.code === 'unavailable') {
        // expected error if the item is not in the cache
        response = undefined;
      } else {
        // unexpected error
        throw error;
      }
    }

    if (
      !response ||
      isCacheMiss(response, lib.firestore.QuerySnapshot, lib.firestore.DocumentSnapshot)
    ) {
      console.debug(`CacheService: cache miss (firestore) - ${cacheKey}`);
      response = await request.get({ source: 'server' });
    } else {
      console.debug(`CacheService: cache hit - ${cacheKey}`);
      cacheHit = true;
    }
  } else {
    console.debug(`CacheService: cache miss (no timestamp) - ${cacheKey}`);
    response = await request.get();
  }

  if (!cacheHit && CACHE_ENABLED) {
    storage.set(cacheKey, true, { maxAgeInMs: maxAge * 1000 });
  }

  return response;
}

/**
 * Wrapper for get for a basic document fetch operation
 */
async function getDocument<T>(
  session: FirebaseSession,
  path: string,
  maxAge = DEFAULT_MAX_AGE
): Promise<T | undefined> {
  const { lib } = session;
  const document = FirebaseUtils.getDocument<T>(session, path);
  const key = cacheKey(path);
  const result = await get(lib, key, document, maxAge);
  return result?.exists ? (result.data() as T | undefined) : undefined;
}

/**
 * Wrapper for get for a basic collection fetch operation
 */
async function getCollection<T>(
  session: FirebaseSession,
  path: string,
  maxAge = DEFAULT_MAX_AGE
): Promise<CollectionModel<T>> {
  const { lib } = session;
  const collection = FirebaseUtils.getCollection<T>(session, path);
  const key = cacheKey(path);
  const result = await get(lib, key, collection, maxAge);
  return FirebaseUtils.mapCollection(result);
}

/**
 * Test a response based on type to see if it is in the cache
 */
function isCacheMiss<T>(
  response: CachableResponse<T>,
  QuerySnapshot: typeof firebase.firestore.QuerySnapshot,
  DocumentSnapshot: typeof firebase.firestore.DocumentSnapshot
): boolean {
  if (response instanceof QuerySnapshot) {
    return !!response.empty;
  } else if (response instanceof DocumentSnapshot) {
    return !response.exists;
  }

  return true;
}

/**
 * Clear a given key from the key-value store so subsequent requests must hit the server
 *
 * This does not clear data from the firebase request cache
 */
async function clear(key: string): Promise<void> {
  try {
    await idb.del(key, customStore);
  } catch (error) {
    // TODO: log this
    console.warn(error);
  }
}

/**
 * Clear all data from the key-value store so subsequent requests must hit the server
 *
 * This does not clear data from the firebase request cache
 */
async function clearAll(): Promise<void> {
  try {
    await idb.clear(customStore);
  } catch (error) {
    // TODO: log this
    console.warn(error);
  }
}

function cacheKey(
  base: string,
  params?: Record<string, string | number | boolean | null | undefined>
): CacheKey {
  let key = base;

  if (params) {
    const paramNames = Object.keys(params).sort();

    if (paramNames.length > 0) {
      const queryString = paramNames
        .map((paramName) => `${paramName}=${params[paramName]}`)
        .join('&');
      key += `?${queryString}`;
    }
  }

  return key as CacheKey;
}

export const CacheService = {
  cacheKey,
  clear,
  clearAll,
  get,
  getCollection,
  getDocument,
} as const;
