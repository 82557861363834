import {
  reducer,
  dismissNotification,
  dismissNotifications,
  notify,
  NewNotification,
} from 'reapop';

export default reducer();

export type NotificationType = Pick<
  NewNotification,
  'id' | 'title' | 'message' | 'position' | 'dismissible' | 'onAdd' | 'onDismiss' | 'allowHTML'
> & {
  manualDismiss?: boolean;
};

const defaultSettings: NewNotification = {
  dismissible: true,
  dismissAfter: 6666,
  position: 'top-right',
  showDismissButton: true,
};

function createNotificationTypeAction(type: NewNotification['status']) {
  return (notification: NotificationType) => {
    const { manualDismiss, ...userSettings } = notification;

    return notify({
      ...defaultSettings,
      ...userSettings,
      dismissAfter: manualDismiss ? 0 : defaultSettings.dismissAfter,
      status: type,
    });
  };
}

export const NotificationActions = {
  info: createNotificationTypeAction('info'),
  error: createNotificationTypeAction('error'),
  success: createNotificationTypeAction('success'),
  warning: createNotificationTypeAction('warning'),
  dismiss: dismissNotification,
  dismissAll: dismissNotifications,
} as const;
